import React, { useEffect, useState } from "react";
import { useMutateCreateImage } from "./hooks/useApiHook";
import { ErrorMessage, useFormikContext } from "formik";
import { ErrorToast } from "../../Utils/SweetAlert";
import "./profileSelect.css";

const AdminProfileSelect = ({ field, Label, require,className,height = 100, width = 100  }) => {

  const addImageApi = useMutateCreateImage();
  const { setFieldValue } = useFormikContext();
  const [style, setStyle] = useState({ display: 'none' });
  const [imgHoverStyle, setImgHoverStyle] = useState({ opacity: '100%' });

  const handleChange = async (event) => {
    const files = event.target.files[0];
    if (files) {
      event.preventDefault();

      const formData = new FormData();
      formData.append(
        "images", files
      );

      addImageApi.mutate(formData, {
        onSuccess: (response) => {
          setFieldValue(
            `${field.name}`,
            response?.data[0]
          );
        },
        onError: (error) => {
          ErrorToast(
            error?.response?.data
              ?.message || "Server error."
          );
        },
      });
    }
  }

  return (
    <div>
      <div className={className}>
      {Label && (
        <label className="">
          {Label} {require && <span className="text-danger">*</span>}
        </label>
      )}
        <div className="">
          {field.value ? (
            <div style={{
              display: "flex", justifyContent: "center",
              alignItems: "center",

            }}>
              <div onMouseEnter={e => {
                setStyle({ display: 'block' });
                setImgHoverStyle({ opacity: "50%" });
              }}
                onMouseLeave={e => {
                  setStyle({ display: 'none' });
                  setImgHoverStyle({ opacity: "100%" });
                }}>
                <img
                  src={field.value}
                  alt={field.value}
                  style={imgHoverStyle}
                  name="image"
                  className="img-border image-responsive"
                />
              </div>
              <div className="position-absolute" onMouseEnter={e => { setStyle({ display: 'block' }); setImgHoverStyle({ opacity: "50%" }); }}
                onMouseLeave={e => { setStyle({ display: 'none' }); setImgHoverStyle({ opacity: "100%" }); }} style={style}>
                <p className="mb-0">
                  <label htmlFor="imgSelect" className="replace-btn">
                    Replace
                    <input
                      id="imgSelect"
                      type="file"
                      multiple={false}
                      name={field.name}
                      accept="image/png, image/jpeg, image/jpg"
                      className="filetype d-none"
                      onChange={handleChange}
                    />
                  </label>
                </p>
                <p>
                  <button type="button" className="remove-btn"
                    onClick={() => setFieldValue(
                      `${field.name}`,
                      ""
                    )}>
                    Remove
                  </button>
                </p>
              </div>
            </div>

          ) : (
            <label htmlFor="profileSelect">
              <div className="product-profile  addproduct-class">
                <div className="text-center">
                  <div style={{
                    padding: "30px 27px", background: "#D8D4D4", borderRadius: "100%", color: "white", fontSize: "50px", display: "inline-block"
                  }}>+</div> <br />
                  <label className="product-label-profile label-prod-pro">
                    Add Image
                    <input
                      id="profileSelect"
                      type="file"
                      multiple={false}
                      name={field.name}
                      accept="image/png, image/jpeg, image/jpg"
                      className="filetype d-none"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
            </label>
          )}
        </div>
        <ErrorMessage
          name={field.name}
          component="div"
          className="text-danger"
        />
      </div>
    </div>
  );
};

export default AdminProfileSelect;
