import * as Yup from "yup";

// export const ProductIntialValues = {
//   productName: '',
//   variations: [
//     {
//       productVariations: "",
//       productVariationsValue:"",
//       productLink:""
//     }
//   ]
// };



// Assuming you have a list of existing categories
const existingCategories = ["Electronics", "Books", "Clothing"]; // Example list

export const addCategoryValidationSchema = Yup.object()
  .shape({
    name: Yup.string()
      .required("Please enter category name")
      .test(
        'unique-category', 
        'Category already exists', 
        function(value) {
          return !existingCategories.includes(value);
        }
      ),
  })
  .defined();


export const CategoryIntialValues = {
  name: "",
  guide: null,
  parentCategoryId: null,
};

// export const addCategoryValidationSchema = Yup.object()
//   .shape({
//     name: Yup.string().required("Please enter category name"),
//   })
//   .defined();

export const editCategoryValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter category name"),
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: "",
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const CategorySearchBarInitialValues = {
  search: "",
};
