/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';

const AdminHeader = (props) => {

  return (
    <div>
      <div className='header-left header-content header-left-one'>
        <Link to='/admin/' className='logo'>
          <img src='/images/logo.png' className='header-logo' alt='user.png' />
        </Link>
        <Link to='/admin/' className='logo logo-small ms-3' style={{ marginRight: "25%" }}>
          <img src='/images/small_logo.png' className='header-small-logo' alt='user.png' />
        </Link>
      </div>
      <Link to='#' id='toggle_btn' className='header-toggle-btn'>
        <i className='fas fa-bars'></i>
      </Link>
      <Link to='#' className='mobile_btn' id='mobile_btn' onClick={() => props.onClick(!props.showSideBar)}>
        <i className='fas fa-bars'></i>
      </Link>

    </div>
  );
};

export default AdminHeader;
