import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useMutateCreateUser } from '../../hooks/useApiHooks';
import InputWithLabel from '../../../../Components/InputWithLabel';
import { ErrorToast, SuccessToast } from '../../../../Utils/SweetAlert';
import { AddUserValidationSchema, userInitialValues } from '../../constant';
import PassowrdField from '../../../../Components/PasswordField';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "../../newstyle.css";
import AdminProfileSelect from '../../../../Components/AdminProfileSelect';

const AddAdminUser = () => {
  const navigate = useNavigate();

  const addAdminUser = useMutateCreateUser();

  /**
   * Used to fetch last name from url
   * @param {string} url "Url of selected image file" 
   * @returns "Spliced file name from selected image file"
   */
  function getLastNameFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  const handleSubmitAddAdminUser = (values) => {
    const reqData = {
      profilePic: values?.profilePic ? getLastNameFromUrl(values?.profilePic) : "",
      name: values?.name,
      email: values?.email,
      countryCode: `${"+"}${values?.countryCode}`,
      mobileNo: values?.mobileNo,
      status: values?.status,
      password: values?.password
    };

    addAdminUser.mutate(reqData, {
      onSuccess: (response) => {
        if (response?.status != 0) {
          SuccessToast(response?.message || 'Added Successful.');
          navigate('/admin/admin-user-listing');
        } else {
          ErrorToast(response?.message || 'Server Error.');
        }
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || 'Server Error.');
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>OVE | Admin Add Admin</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card card-border overflow-visible'>
                <div className='card-body'>
                  <div className='page-header d-block'>
                    <div className='row'>
                      <div className='col'>
                        <h3 className='page-title'>Add Admin</h3>
                        <ul className='breadcrumb'>
                          <li className='breadcrumb-item'>
                            <Link to='/admin/dashboard' className='text-color text-decoration-underline'>
                              Dashboard
                            </Link>
                          </li>
                          <li className='breadcrumb-item sub-title'>
                            <Link to='/admin/admin-user-listing' className=''>
                              Admin
                            </Link>
                          </li>

                          <li className='breadcrumb-item sub-title'>Add Admin</li>
                        </ul>
                      </div>
                      <div className='col-auto d-flex align-items-center justify-content-center'>
                        <button onClick={() => navigate('/admin/admin-user-listing')} className='back-btn'>
                          <i className='fas fa-angle-double-left me-2' /> Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <section className='section-border'>
                    <Formik
                      initialValues={userInitialValues}
                      validationSchema={AddUserValidationSchema}
                      validateOnChange
                      onSubmit={handleSubmitAddAdminUser}
                    >
                      {(formik) => {

                        const { setFieldValue, values, isSubmitting } = formik;

                        return (
                          <Form>
                            <div className='row'>
                              <div className="col-md-3 d-flex justify-content-center">
                                <Field
                                  name="profilePic"
                                  component={AdminProfileSelect}
                                  require={false}
                                />
                              </div>
                              <div className="col-md-9 d-flex justify-content-between">
                                <div className='row gy-4 me-1'>
                                  <div className='col-md-6'>
                                    <Field
                                      Label='Name'
                                      type='text'
                                      name='name'
                                      placeholder='Enter User Name'
                                      component={InputWithLabel}
                                    />
                                  </div>
                                  <div className='col-md-6'>
                                    <Field
                                      Label='Email'
                                      type='text'
                                      name='email'
                                      placeholder='Enter Email'
                                      component={InputWithLabel}
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <label>
                                      Mobile Number <span className='text-danger'>*</span>
                                    </label>
                                    <PhoneInput
                                      name="mobileNo"
                                      country={'gb'}
                                      countryCodeEditable={false}
                                      onChange={(phone1, phone2, phone3) => {
                                        setFieldValue("mobileNo", phone1?.replace(`${phone2.dialCode}`, ""));
                                        setFieldValue("countryCode", phone2.dialCode);
                                      }}
                                      inputStyle={{
                                        background: "#F7F5F5",
                                        height: "38px",
                                        boxShadow: "none",
                                        borderRadius: "8px",
                                        width: "100%"
                                      }}
                                    />
                                    <ErrorMessage name="mobileNo" component='div' className='text-danger' />
                                  </div>
                                  <div className='col-md-6'>
                                    <label className=''>
                                      Password <span className='text-danger'>*</span>
                                    </label>

                                    <Field component={PassowrdField} name='password' placeholder='Enter Password' />
                                    <ErrorMessage name='password' component='div' className='text-danger' />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='text-center mt-3'>
                              <button type='submit' className='add-button'>
                                Add User
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAdminUser;