import { useMutation } from '@tanstack/react-query';
import { UpdateVersion } from '../../../../Api/setting';
import {UpdateUserMessage} from '../../../../Api/setting';

export const useMutateUpdateVersion = () =>
  useMutation({
    mutationFn: (data) => UpdateVersion(data),
  });

  export const useMutateUpdateUserMessage = () =>
    useMutation({
      mutationFn: (data) => UpdateUserMessage(data),
    });
