import React from "react";
import PropTypes from "prop-types";
import { getImage } from "../../Utils/Image-helpers";


function NameInitial({ name, type, className, style }) {
  switch (type) {
    case "event": {
      return (
        <span
          /*style={{
            backgroundColor: "#a2f1f7",
            color: "#7638FF",
            border: "1px solid #362ead",
            fontSize: "20px",
            width: "50px",
            height: "50px",
          }}*/
          className={`name-centered-rounded image-profile-blank default-image ${className}`}
          style={style}
        >
          {getImage(name)}
        </span>
      );
    }

    default: {
      return (
        <span
          /* style={{
            backgroundColor: "#a2f1f7",
            color: "#7638FF",
            border: "1px solid #362ead",
            fontSize: "20px",
            width: "50px",
            height: "50px",
          }}*/
          className={`name-centered-rounded image-profile-blank default-image ${className}`}
          style={style}
        >
          {getImage(name)}
        </span>
      );
    }
  }
}

NameInitial.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
};

NameInitial.defaultProps = {
  name: "LI",
  type: "image",
};

export default NameInitial;
