import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GuestRoute from './GuestRoute';
import { Login, ResetPassword, ForgotPassword } from '../Pages/Authentications';

const AuthRoutes = () => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<GuestRoute Component={Login} />} />
        <Route exact path='/forgot-password' element={<GuestRoute Component={ForgotPassword} />} />
        <Route exact path='/reset-password' element={<GuestRoute Component={ResetPassword} />} />
      </Routes>
    </>
  );
};
export default AuthRoutes;
