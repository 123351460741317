import * as Yup from 'yup';



export const EmojiIntialValues = {
  name: '',
  image:''
};





export const EmojiValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter name'),
  image: Yup.mixed()
    .required('Image is required')
    .test(
      "fileType",
      "Only jpg, jpeg, and png files are allowed",
      (value) => {
        if (!value) return false;

        const allowedFormats = ["image/jpeg", "image/png", "image/jpg"];

        if (value instanceof File) {
          return allowedFormats.includes(value.type); 
        } else {
          const extension = value.split(".").pop().toLowerCase();
          return allowedFormats.includes(`image/${extension}`);
        }
      }
    ),
});





export const InsightSearchBarInitialValues = {
  search: '',
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const EmojiSearchBarInitialValues = {
  search: '',
};
