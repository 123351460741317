// import React from 'react';
// import { ErrorMessage, useFormikContext } from 'formik';
// import './style.css';

// const InputWithLabel = ({ field, type, placeholder, Label, require, disabled, innerRef }) => {
//   const { handleChange } = useFormikContext();

//   return (
//     <div className='form-group'>
//       <label className=''>
//         {Label} {require !== false && <span className='text-danger'>*</span>}
//       </label>
//       <input
//         className="custom-input"
//         {...field}
//         type={type}
//         value={field.value}
//         onChange={handleChange}
//         name={field.name}
//         placeholder={placeholder}
//         disabled={disabled}
//         ref={innerRef}
//       />
//       <ErrorMessage name={field.name} component='div' className='text-danger' />
//     </div>
//   );
// };

// export default InputWithLabel;


import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import './style.css';

const InputWithLabel = ({ field, type, placeholder, Label, require, disabled, innerRef, className, style }) => {
  const { handleChange } = useFormikContext();

  return (
    <div className='form-group'>
      <label>
        {Label} {require !== false && <span className='text-danger'>*</span>}
      </label>
      <input
        className={`custom-input ${className || ''}`} 
        {...field}
        type={type}
        value={field.value}
        onChange={handleChange}
        name={field.name}
        placeholder={placeholder}
        disabled={disabled}
        ref={innerRef}
        style={style} // Apply style here
      />
      <ErrorMessage name={field.name} component='div' className='text-danger' />
    </div>
  );
};

export default InputWithLabel;
