import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CategoryIntialValues, editCategoryValidationSchema } from "../../constant";
import { useMutateEditCategory } from "../../hooks/useApiHook";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { useQuery } from "@tanstack/react-query";
import InputWithLabel from "../../../../Components/InputWithLabel";
import { GetGuideOptions, GetSingleCategory } from "../../../../Api/category";
import CustomSelect from "../../../../Utils/CustomSelect";
import { MdModeEditOutline } from "react-icons/md";
import MultiLevelSelect from "../../../../Components/MultiLevelSelectBox";
import { GetCategoryOptions } from "../../../../Api/productVariations";

const EditCategory = () => {

  const navigate = useNavigate();
  const formRef = useRef();
  const stateData = useLocation();

  const { id } = stateData?.state || "";
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (option) => {
    setSelectedValue(option);
  };

  const editCategoryApi = useMutateEditCategory();

  const { data, refetch } = useQuery({
    queryKey: ["get-single-category"],
    queryFn: () => GetSingleCategory({ id: id }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const { isFetching: isGuideOptionsLoading, data: GuideOptions, } = useQuery({
    queryKey: ["get-guide-option"],
    queryFn: () => GetGuideOptions({ search: "" }),

    select: (data) => [
      ...data.data.map((item) => ({
        ...item,
        value: item._id,
        label: item.name,
      })),
    ],
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  useEffect(() => {
    if (data) {
      if (formRef?.current?.values) {
        formRef?.current?.setValues({
          name: data?.data?.name,
          guide: data?.data?.guideProductId || null,
          parentCategoryId: data?.data?.parentCategoryId || null
        });
      }
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);


  const handleSubmitEditCategory = (values) => {
    const reqData = {
      id: id,
      name: values?.name,
      guideProductId: values?.guide || null,
      parentCategoryId: values?.parentCategoryId || null
    };
    editCategoryApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response?.message || " Product Updated.");
        navigate("/admin/category-listing");
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || "Server Error.");
      },
    });
  };


  return (
    <>
      <Helmet>
        <title>OVE | Admin Edit Category</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-border overflow-visible">
                <div className="card-body">
                  <div className="page-header d-block">
                    <div className="row">
                      <div className="col">
                        <h3 className="page-title">Edit Category</h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              className="text-color text-decoration-underline"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">
                            <Link
                              to="/admin/category-listing"
                              className=""
                            >
                              Category
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">Edit Category</li>
                        </ul>
                      </div>
                      <div className='col-auto d-flex align-items-center justify-content-center'>
                        <button onClick={() => navigate('/admin/category-listing')} className='back-btn'>
                          <i className='fas fa-angle-double-left me-2' /> Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <section className="section-border">
                    <Formik
                      initialValues={CategoryIntialValues}
                      validationSchema={editCategoryValidationSchema}
                      validateOnChange
                      onSubmit={handleSubmitEditCategory}
                      innerRef={formRef}
                    >
                      {(formik) => {
                        const {
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                          values,
                        } = formik;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  Label="Name"
                                  type="text"
                                  name="name"
                                  placeholder="Enter Category Name"
                                  component={InputWithLabel}
                                />
                              </div>

                              <div className="col-md-6">
                                <label className="" htmlFor="guide">
                                  Guide
                                </label>
                                <CustomSelect
                                  name="guide"
                                  options={GuideOptions}
                                  placeholder="Select Guide"
                                  isLoading={isGuideOptionsLoading}
                                  onChange={(option) =>
                                    // setFieldValue("guide", option.value)
                                    setFieldValue("guide", option ? option.value : null)
                                  }
                                  value={GuideOptions?.find(
                                    (item) => item.value === values.guide
                                  )}
                                  isClearable
                                />
                                <ErrorMessage
                                  name="guide"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-md-6 ps-0 mt-3">
                              <label htmlFor="parentCategoryId">Category</label>
                              <MultiLevelSelect
                                name="parentCategoryId"
                                defaultValues={values?.parentCategoryId}
                              />
                            </div>

                            <div className="text-center mt-3">
                              <button
                                type="submit"
                                className="add-category-button"
                              >
                                Edit Category
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCategory;
