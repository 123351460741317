import * as Yup from "yup";
import { object, array, string, number } from "yup";

export const ProductIntialValues = {
  // name: "",
  // variations: [
  //   {
  //     name: "",
  //   },
  // ],
  name: '',
  variations: [{ name: '' }]
};

export const ProductValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Product name is required"),
    variations: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Please enter product variation"),
        })
      )
      .test("is-unique", "Variation names must be unique", function (value) {
        if (!value) return true; // Handle case where value is undefined
        const names = value.map((v) => v.name);
        const uniqueNames = new Set(names);
        if (names.length !== uniqueNames.size) {
          return this.createError({
            message: "Variation names must be unique",
            path: `variations.${names.findIndex(
              (name, index) => names.indexOf(name) !== index
            )}.name`,
          });
        }
        return true;
      })
      .required("Please enter Product variations"),
    // name: Yup.string().required("Please enter name"),
    // variations: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       name: Yup.string().required("Please enter Product variations"),
    //     })
    //   )
    //   .test("is-unique", "Variation names must be unique", function (value) {
    //     let names = value.map((v) => v.name);
    //     let uniqueNames = new Set(names);
    //     if (names.length !== uniqueNames.size) {
    //       return this.createError({
    //         message: "Variation names must be unique",
    //         path: `variations[${names.findIndex(
    //           (name, index) => names.indexOf(name) !== index
    //         )}].name`,
    //       });
    //     }
    //     return true;
    //   })
    //   .required("Please enter Product variations"),
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: "",
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const ProductVariationSearchBarInitialValues = {
  search: "",
};
