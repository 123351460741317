import { useMutation } from "@tanstack/react-query";
import {
  AddImages,
  CreateProduct,
  DeleteProduct,
  UpdateProduct,
} from "../../../Api/product";

export const useMutateCreateProduct = () =>
  useMutation({
    mutationFn: (data) => CreateProduct(data),
  });

export const useMutateCreateImage = () =>
  useMutation({
    mutationFn: (data) => AddImages(data),
  });

export const useMutateEditProduct = () =>
  useMutation({
    mutationFn: (data) => UpdateProduct(data),
  });

export const useMutateDeleteProduct = () =>
  useMutation({
    mutationFn: (data) => DeleteProduct(data),
  });
