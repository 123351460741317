import React, { useEffect, useRef, useState } from "react";
import "../../style.css";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { useMutateUpdateUserMessage } from "../hooks/useApihook";
import { Field, Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetUserMessage } from "../../../../Api/setting";
import {
  UpdateAiChatIntialValues,
  UpdateAiChatValidationSchema,
} from "./constant";
import SettingTextArea from "./SettingTextArea";

const Aichatusersetting = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [copied, setCopied] = useState(null);

  const { data, error, isLoading } = useQuery({
    queryKey: ["get-user-message"],
    queryFn: GetUserMessage,
    onSuccess: (response) => {
      if (formRef.current) {
        formRef.current.setValues({ message: response.data.data.message });
      }
    },
    onError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  useEffect(() => {
    if (data) {
      UpdateAiChatIntialValues.message = data.data.data.message;
      if (formRef.current) {
        formRef.current.setValues({ message: data.data.data.message });
      }
    }
  }, [data]);

  const updateUserMessageApi = useMutateUpdateUserMessage();

  const handleSubmitEditUserMessage = (values, { setSubmitting }) => {
    const reqData = { message: values.message || "" };
    updateUserMessageApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response.message || "User Message Updated.");
        setSubmitting(false);
        navigate("/admin/setting");
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || "Server Error.");
        setSubmitting(false);
      },
    });
  };

  const handleCopy = (text, key) => {
    navigator.clipboard.writeText(text);
    setCopied(key);
    setTimeout(() => setCopied(null), 2000);
  };

  return (
    <div>
      <div className="">
        <h3 className="text-center">Ai Chat</h3>
        <div className="">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <table className="table align-items-center table--light">
                    <thead >
                      <tr>
                        <th>Short Code</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span
                            className={`copyable-text ${
                              copied === "message" ? "copied" : ""
                            }`}
                            onClick={() => handleCopy("{{message}}", "message")}
                          >
                            <span className="text">{"{{message}}"}</span>
                            <span className="copy-label">Copy</span>
                            <span className="copied-label">Copied</span>
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          User Message
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span
                            className={`copyable-text ${
                              copied === "age" ? "copied" : ""
                            }`}
                            onClick={() => handleCopy("{{age}}", "age")}
                          >
                            <span className="text">{"{{age}}"}</span>
                            <span className="copy-label">Copy</span>
                            <span className="copied-label">Copied</span>
                          </span>
                        </td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          User Age
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={UpdateAiChatIntialValues}
                    validationSchema={UpdateAiChatValidationSchema}
                    validateOnChange
                    innerRef={formRef}
                    onSubmit={handleSubmitEditUserMessage}
                  >
                    {(formik) => {
                      const { isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                Label="Chatbot Prompt Message"
                                name="message"
                                placeholder="Enter Chatbot Prompt Message"
                                component={SettingTextArea}
                              />
                            </div>
                          </div>
                          <div className="text-end mt-5">
                            <button
                              type="submit"
                              className="btn btn-them-gray chat-btn"
                              disabled={isSubmitting}
                            >
                              Save Changes
                              {isSubmitting && (
                                <i className="fas fa-spinner fa-spin ms-2"></i>
                              )}
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aichatusersetting;
