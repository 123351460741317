import * as Yup from "yup";

export const GuideIntialValues = {
  name: "",
  image: "",
  productDetails: [
    {
      description: "",
      images: [],
      howToUse: [
        {
          description: "",
          images: [],
        },
      ],
      howToRemove: [
        {
          description: "",
          images: [],
        },
      ],
      howToClean: [
        {
          description: "",
          images: [],
        },
      ],
      howToChange: [
        {
          description: "",
          images: [],
        },
      ],
    },
  ],
};

export const AddGuideValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter product name"),
    image: Yup.string().required("Please add image"),
    productDetails: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required("Please enter description"),
        images: Yup.array()
          .min(1, "Please add atleast one image")
          .required("Please add images"),
        howToUse: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToRemove: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToClean: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToChange: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
      })
    ),
  })
  .defined();

export const EditGuideValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter product name"),
    image: Yup.string().required("Please select image"),
    productDetails: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required("Please enter description"),
        images: Yup.array()
          .min(1, "Please add atleast one image")
          .required("Please add images"),
        howToUse: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToRemove: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToClean: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
        howToChange: Yup.array().of(
          Yup.object().shape({
            description: Yup.string()
              .required("Please enter description")
              .nullable(),
            images: Yup.array()
              .min(1, "Please add atleast one image")
              .required("Please add images")
              .nullable(),
          })
        ),
      })
    ),
  })
  .defined();

export const GuideSearchBarInitialValues = {
  search: "",
};
