import * as Yup from "yup";

export const WelfareIntialValues = {
  name: "",
  section: [
    {
      name: "",
      logo: "",
      description: "",
      website: "",
      phoneCode: "",
      phoneNumber: "",
      helplineNumber: "",
      liveHelpChat: false,
      liveHelpChatLink: "",
      openingHours: "",
      liveHelpChatDescription: "",
    },
  ],
};

export const AddWelfareValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter welfare name"),
    section: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Please enter name"),
        website: Yup.string().url("Please enter valid website"),
        liveHelpChatLink: Yup.string().url("Please enter valid link"),
      })
    ),
  })
  .defined();

export const EditWelfareValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter welfare name"),
    section: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Please enter name"),
        website: Yup.string().url("Please enter valid website"),
        liveHelpChatLink: Yup.string().url("Please enter valid link"),
      })
    ),
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: "",
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const ProductSearchBarInitialValues = {
  search: "",
};
