import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routings from './routes';
import { HelmetProvider } from 'react-helmet-async';
// import { AppContext } from './context/appContext';

function App() {

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    setIsSmallScreen(window.innerWidth < 1024);
  }, []);

  return (
    <div className='App'>
      {/* <AppContext.Provider> */}
      <HelmetProvider>
        {isSmallScreen ?
          <>
            <div className='small-screen'>
              <svg stroke="none" fill="#000000" stroke-width="32" version="1" viewBox="0 0 48 48" enable-background="new 0 0 48 48" height="180px" width="180spx" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" stroke="none" d="M4,39V7c0-2.2,1.8-4,4-4h22c2.2,0,4,1.8,4,4v32c0,2.2-1.8,4-4,4H8C5.8,43,4,41.2,4,39z"></path><path fill="#BBDEFB" stroke="none" d="M30,6H8C7.4,6,7,6.4,7,7v29c0,0.6,0.4,1,1,1h22c0.6,0,1-0.4,1-1V7C31,6.4,30.6,6,30,6z"></path><rect x="15" y="39" fill="#FFFFFF" width="6" height="2"></rect><path fill="#000000" stroke="none" d="M24,41V17c0-2.2,1.8-4,4-4h12c2.2,0,4,1.8,4,4v24c0,2.2-1.8,4-4,4H28C25.8,45,24,43.2,24,41z"></path><path stroke="none" fill="#FFFFFF" d="M40,16H28c-0.6,0-1,0.4-1,1v22c0,0.6,0.4,1,1,1h12c0.6,0,1-0.4,1-1V17C41,16.4,40.6,16,40,16z"></path><circle fill="#FFFFFF" cx="34" cy="42.5" r="1.5"></circle></svg>
              <div>Your screen is too small. For the best experience,
                <br />please use a MacBook or desktop.</div>
            </div>
          </> :
          <>
            <Router>
              <Routings />
            </Router>
          </>}
      </HelmetProvider>
      {/* </AppContext.Provider> */}
    </div>
  );
}
export default App;
