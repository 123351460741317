import Api from "../Utils/Axios";

const token = localStorage.getItem("accessToken");
export const GetAppVersion = async () => {
  try {
    const response = await Api.get("setting/appVersion");
    return response;
  } catch (error) {
    console.error("error: ", error);
  }
};

export const UpdateVersion = async (data) => {
  const response = await Api.put("setting/appVersion", data);
  return response.data;
}

export const GetUserMessage = async () => {
  const response = await Api.get('setting/userMessage');
  return response;
};

export const UpdateUserMessage = async (data) => {
  const response = await Api.put('setting/userMessage', data);
  return response.data.message;
}