import React from "react";
import { Confirmation, SuccessToast } from "../../Utils/SweetAlert";
import { useNavigate } from "react-router-dom";
import { UserLogout } from "../../Api/auth";

const Logout = () => {
    const navigate = useNavigate();

    Confirmation('Are you sure you want to Logout?').then(async (confirm) => {
        if (confirm.isConfirmed) {
            await UserLogout().then((res) => {
                SuccessToast(res?.data?.message || "Logout successfully.");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userData");
                window.location.assign("/");
            }).catch((error) => {
                ErrorToast(error?.message || "Something went wrong.");
            })
        } else {
            navigate("/")
        }
    });
}

export default Logout;