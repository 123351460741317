import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { addCategoryValidationSchema, CategoryIntialValues } from "../../constant";
import { useMutateCreateCategory } from "../../hooks/useApiHook";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import InputWithLabel from "../../../../Components/InputWithLabel";
import { GetGuideOptions } from "../../../../Api/category";
import CustomSelect from "../../../../Utils/CustomSelect";
import MultiLevelSelect from "../../../../Components/MultiLevelSelectBox";
import "../../newStyle.css";

const AddCategory = () => {
  const navigate = useNavigate();
  const addCategoryApi = useMutateCreateCategory();

  const [isOpen, setIsOpen] = useState(false);


  const { isFetching: isGuideOptionsLoading, data: guideOptions } = useQuery({
    queryKey: ["get-guide-option"],
    queryFn: () => GetGuideOptions({ search: "" }),
    select: (data) => [
      ...data.data.map((item) => ({
        ...item,
        value: item._id,
        label: item.name,
      })),
    ],
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const handleSubmitAddCategory = (values) => {
    const reqData = {
      name: values?.name,
      guideProductId: values?.guide || null,
      parentCategoryId: values?.parentCategoryId ||null
    };

    addCategoryApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response?.message || "Added Successful.");
        navigate("/admin/category-listing");
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || "Server Error.");
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>OVE | Admin Add Categoty</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-border overflow-visible">
                <div className="card-body">
                  <div className="page-header d-block">
                    <div className="row">
                      <div className="col">
                        <h3 className="page-title">Add Category </h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              className="text-color text-decoration-underline"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">
                            <Link
                              to="/admin/category-listing"
                              className=""
                            >
                              Category
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">Add Category</li>
                        </ul>
                      </div>
                      <div className='col-auto d-flex align-items-center justify-content-center'>
                        <button onClick={() => navigate('/admin/category-listing')} className='back-btn'>
                          <i className='fas fa-angle-double-left me-2' /> Back
                        </button>
                      </div>
                      
                    </div>
                  </div>
                  <section className="section-border">
                    <Formik
                      initialValues={CategoryIntialValues}
                      validationSchema={addCategoryValidationSchema}
                      validateOnChange
                      onSubmit={handleSubmitAddCategory}
                    >
                      {(formik) => {
                        const { setFieldValue } = formik;
                        return (
                          <Form>
                            <div className="row">
                              <div className="col-md-6">
                                <Field
                                  Label="Name"
                                  type="text"
                                  name="name"
                                  placeholder="Enter Category Name"
                                  component={InputWithLabel}
                                />
                              </div>
                              

                              <div className="col-md-6">
                                <label htmlFor="guide">Guide</label>
                                <CustomSelect
                                  name="guide"
                                  placeholder="Select Guide"
                                  options={guideOptions}
                                  isLoading={isGuideOptionsLoading}
                                  onChange={(option) => {
                                    setFieldValue("guide", option ? option.value : null);
                                  }}
                                  isClearable
                                  // onChange={(option) =>
                                  //   setFieldValue("guide", option.value)
                                  // }
                                />
                                <ErrorMessage
                                  name="guide"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>

                              <div className="col-md-6 mt-3">
                                <label htmlFor="guide">Category</label>
                                <MultiLevelSelect
                                  name="parentCategoryId"
                                />
                              </div>
                            </div>
                            <div className="text-center mt-3">
                              <button
                                type="submit"
                                className="add-category-button"
                              >
                                Add Category
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
