import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';

const TextArea = ({ field, placeholder, Label, require, innerRef,className }) => {
    const { handleChange } = useFormikContext();

    return (
        <div className='form-group'>
            <label className=''>
                {Label} {require !== false && <span className='text-danger'>*</span>}
            </label>
            <textarea
               
                className={`input-customize ${className || ''}`} 
                {...field}
                value={field.value}
                onChange={handleChange}
                name={field.name}
                placeholder={placeholder}
                style={{ height: "220px",
                    backgroundColor: "#F7F5F5", 
                    borderRadius: "10px", 
                    outline:"none",
                 }}
                ref={innerRef}
            />
            <ErrorMessage name={field.name} component='div' className='text-danger' />
        </div>
    );
};

export default TextArea;
