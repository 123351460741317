import Api from '../Utils/Axios';

export const GetProductVariationsList = async (data) => {
  const response = await Api.get('productVariation/', {params:data});
  return response.data;
};

export const GetProductVariationsOptions = async (data) => {
  const response = await Api.get('productVariation/list', {params:data});
  return response.data;
};

export const GetCategoryOptions = async (data) => {
  const response = await Api.get('category/list', data);
  return response.data;
};

export const GetFlowOptions = async (data) => {
  const response = await Api.get('flow/list', data);
  return response.data;
};

export const CreateProductVariations = async (data) => {
  const response = await Api.post('productVariation/', data);
  return response.data;
};

export const UpdateProductVariations = async (data) => {
  const response = await Api.put('productVariation/', data);
  return response.data;
}

export const GetSingleProductVariation = async ({ id }) => {
  const response = await Api.get(`productVariation/${id}`);
  return response.data;
};

export const PostIsActive = async (data) => {
  const response = await Api.post('admin/products/status', data);
  return response.data;
};

export const DeleteProductVariation = async (data) => {
  const response = await Api.delete(`productVariation/${data}`);
  return response.data;
};
