import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import './style.css';
import { Link } from "react-router-dom";
import VersionSetting from './components/versionSetting/versionSetting';
import { MdOutlineSecurityUpdate } from "react-icons/md";
import { HiMiniChatBubbleBottomCenterText } from "react-icons/hi2";
import Aichatusersetting from './components/aichatSetting/usermessage';

const Setting = () => {

  const [type, setType] = useState('version-setting')

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Ove | Admin setting</title>
      </Helmet>
      <div className='page-wrapper'>
        <div className='content container-fluid'>
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Setting</h3>
                <ul className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link
                      to="/admin/dashboard"
                      className="text-color text-decoration-underline"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Setting</li>
                </ul>
              </div>
            </div>
          </div>
          {width > 770 ? (
            <div className='setting-container'>
              <div className=''>
                <button type='button' className={type === 'version-setting' ? 'setting-button active-button' : 'setting-button'} onClick={() => setType('version-setting')}>
                  <MdOutlineSecurityUpdate className='icon-width-height me-2' />
                  Version Setting</button>

                <button type='button' className={type === 'ai-chat-setting' ? 'setting-button active-button mt-3' : 'setting-button mt-3'} onClick={() => setType('ai-chat-setting')}>
                  <HiMiniChatBubbleBottomCenterText className='icon-width-height me-2' />
                  Ai Chat Setting</button>
              </div>
              <div className='setting-content-container'>
                {
                  type === 'version-setting' && <VersionSetting />
                }
                {
                  type === 'ai-chat-setting' && <Aichatusersetting />
                }

              </div>
            </div>
          ) : <div className=''>
            <div className='row'>
              <div className="col">
                <button type='button' className={type === 'version-setting' ? 'setting-button-mobile active-button' : 'setting-button-mobile'} onClick={() => setType('version-setting')}>
                  <MdOutlineSecurityUpdate className='icon-width-height me-2' />
                  Version Setting</button>
              </div>
              <div className="col">
                <button type='button' className={type === 'ai-chat-setting' ? 'setting-button-mobile active-button' : 'setting-button-mobile'} onClick={() => setType('ai-chat-setting')}>
                  <HiMiniChatBubbleBottomCenterText className='icon-width-height me-2' />
                  Ai Chat Setting</button>
              </div>
            </div>
            <div className='setting-content-container-mobile mt-3'>
              {
                type === 'version-setting' && <VersionSetting />
              }
              {
                type === 'ai-chat-setting' && <Aichatusersetting />
              }
            </div>
          </div>}


        </div>
      </div>
    </React.Fragment>
  );
};

export default Setting;
