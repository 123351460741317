import React, { useEffect, useRef } from "react";
import "../../style.css";
import { GrUpdate } from "react-icons/gr";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { useMutateUpdateVersion } from "../hooks/useApihook";
import { Field, Form, Formik } from "formik";
import {
  UpdateVersionIntialValues,
  UpdateVersionValidationSchema,
} from "../../constant";
import InputWithLabel from "../../../../Components/InputWithLabel";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { GetAppVersion } from "../../../../Api/setting";

const VersionSetting = () => {
  const navigate = useNavigate();
  const updateVersionApi = useMutateUpdateVersion();
  const formRef = useRef();

  const { data: appVersionData } = useQuery({
    queryKey: ["get-app-version"],
    queryFn: () => GetAppVersion(),
    throwOnError: true,
    onError: (error) => {
      ErrorToast(
        error?.response?.data?.message || error?.message || "Server Error."
      );
    },
  });

  useEffect(() => {
    if (appVersionData) {
      if (formRef?.current?.values) {
        formRef?.current?.setValues({
          minimumIosVersion: appVersionData?.data?.data?.minimumIosVersion,
          minimumAndroidVersion:
            appVersionData?.data?.data?.minimumAndroidVersion,
        });
      }
    }
  }, [appVersionData]);

  const UpdateVersion = (values) => {
    const reqData = {
      minimumIosVersion: values?.minimumIosVersion,
      minimumAndroidVersion: values?.minimumAndroidVersion,
    };

    updateVersionApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response?.message || " Version Updated.");
        navigate("/admin/setting");
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || "Server Error.");
      },
    });
  };

  return (
    <div>
      <div className="">
        <h3 className="text-center">Update Version</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <section className="section-border">
                  <Formik
                    initialValues={UpdateVersionIntialValues}
                    validationSchema={UpdateVersionValidationSchema}
                    validateOnChange
                    innerRef={formRef}
                    onSubmit={UpdateVersion}
                  >
                    {(formik) => {
                      const { isSubmitting } = formik;
                      return (
                        <Form>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                Label="Ios Version"
                                type="number"
                                name="minimumIosVersion"
                                placeholder="Enter Ios Version"
                                component={InputWithLabel}
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                Label="Android Version"
                                type="number"
                                name="minimumAndroidVersion"
                                placeholder="Enter Android Version"
                                component={InputWithLabel}
                              />
                            </div>
                          </div>
                          <div className="text-end mt-5">
                            <button
                              type="submit"
                              className="btn btn-them-gray chat-btn"
                            >
                              <GrUpdate className="icon-width-height me-2" />
                              Update Version
                            </button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VersionSetting;
