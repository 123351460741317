
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "../../newStyle.css";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "../../../../Components/SearchBox";
import { Formik } from "formik";

import { useQuery } from "@tanstack/react-query";
import { GetCategoryList } from "../../../../Api/category"
import { CategorySearchBarInitialValues } from "../../constant";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../../Utils/SweetAlert";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useMutateDeleteCategory } from "../../hooks/useApiHook"
import Pagination from "../../../../Utils";
import { RiEditBoxLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { DeleteModal } from "../../../AdminUser/components/DeleteModal";




const CategoryList = () => {
  const navigate = useNavigate();
  const formRef = useRef();

  const deleteCategory = useMutateDeleteCategory();

  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  // const handleDetailModal = () => {
  //   setOpen(!open);
  // };

  const {
    isFetching: isCategoryLoading,
    data: categoryList,
    refetch,
  } = useQuery({
    queryKey: ["get-category-list", page, limit],
    queryFn: () =>
      GetCategoryList({
        limit: limit,
        page: page === 0 ? 1 : page + 1,
        search: formRef?.current?.values?.search,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
  };

  const handleDeleteRole = (id) => {
    DeleteModal("Are you sure you want to delete category?").then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        deleteCategory.mutate(id, {
          onSuccess: (response) => {
            if (response?.status != 0) {
              SuccessToast(response?.message || "Delete Successful.");
              refetch();
            } else {
              ErrorToast(response?.message || "Server Error.");
            }
          },
          onError: (error) => {
            ErrorToast(error?.response?.data?.message || "Server Error.");
          },
        });
      }
    });
  };
  return (
    <>

      <Helmet>
        <title>OVE | Admin Category </title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="admin-wrapper">
            <div className="page-header">
              <div className="col">
                <h1 className="page-title">Category</h1>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link
                      to="/admin/dashboard"
                      className="text-color text-decoration-underline"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="sub-title">Category</li>
                </ul>
              </div>
              <div className="col-md-4">
                <Formik
                  initialValues={CategorySearchBarInitialValues}
                  innerRef={formRef}
                >
                  {() => {
                    return (
                      <form>
                        <SearchBox refetch={refetch} setPage={setPage} />
                      </form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-auto add-text">
                <Link
                  to="/admin/add-category"
                  className="button  button-them-gray admin-btn"
                >
                  <i className="fas fa-plus " /> Add Category
                </Link>
              </div>
            </div>
            <div className="col-sm-12 table-width">
              <div className="cards-table card-table">
                <div className="card-body">
                  <div className="table-responsive custom-pagination">
                    <table className="table table<img<image-hover datatable table-manager">
                      <thead className="thead-light cat-table">
                        <tr>
                          <th>Name</th>
                          <th>Parent Category</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isCategoryLoading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              <div className="loader-wraper">
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isCategoryLoading && categoryList !== undefined && (
                          <>
                            {categoryList?.data?.data.map((item, index) => {
                              return (
                                <tr key={index} className="table disable-row">
                                  <td className="">{item?.name || "-"}</td>

                                  <td>{item?.parentCategory?.name || "-"}</td>
                                  <td className="user-stickey-actions-body ">
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-secondary insight-stickey-actions-btn "
                                      onClick={() =>
                                        navigate("/admin/edit-category", {
                                          state: { id: item?._id },
                                        })
                                      }
                                      disabled={
                                        item?.role === "superadmin"
                                          ? true
                                          : false
                                      }
                                    >
                                      <IconButton>
                                        <RiEditBoxLine
                                          className="text-secondary icon-color p-0"
                                          size="33"
                                        />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-white"
                                      onClick={() =>
                                        handleDeleteRole(item?._id)
                                      }
                                      disabled={
                                        item?.role === "superadmin"
                                          ? true
                                          : false
                                      }
                                    >
                                      <IconButton>
                                        <MdDelete
                                          className="text-secondary icon-color p-0"
                                          size="33"
                                        />
                                      </IconButton>
                                    </Tooltip>

                                  </td>
                                </tr>
                              );
                            })}
                            {categoryList?.data?.data?.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              totalItem={categoryList?.data?.totalRecords || 0}
              itemsPerPage={limit}
              page={
                !categoryList?.data?.totalRecords ||
                  categoryList?.data?.totalRecords <= 0
                  ? 0
                  : page
              }
              handleChangePage={handlePageChange}
              handleChangeRowsPerPage={handleLimitChange}
              colSpan={6}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryList;
