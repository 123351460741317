import Api from '../Utils/Axios';

export const GetAdminUserListing = async (data) => {
  const response = await Api.get(`user/`, {params:data});
  return response.data;
};

export const GetSingleAdminUser = async ({ Id }) => {
  const response = await Api.get(`user/${Id}`);
  return response.data;
};

export const DeleteAdminUser = async (data) => {
  const response = await Api.delete('user/', { data: data });
  return response.data;
};

export const CreateAdminUser = async (data) => {
  const response = await Api.post('user/', data);
  return response.data;
};

export const UpdateAdminUser = async (data) => {
  const response = await Api.put('user/', data);
  return response.data;
};

export const updateAdminStatus = async (data) => {
  const response = await Api.post('user/status', data);
  return response.data;
};