import Api from "../Utils/Axios";

export const GetProductList = async (data) => {
  const response = await Api.get("product/", { params: data });
  return response.data;
};

export const CreateProduct = async (data) => {
  const response = await Api.post("product/", data);
  return response.data;
};

export const AddImages = async (data) => {
  const response = await Api.post("general/uploadFiles/?returnUrls=true", data);
  return response.data;
};

export const DeleteProduct = async (data) => {
  const response = await Api.delete(`product/${data}`);
  return response.data;
};

export const GetSingleProduct = async ({ id }) => {
  const response = await Api.get(`product/${id}`);
  return response.data;
};

export const UpdateProduct = async (data) => {
  const response = await Api.put("product/", data);
  return response.data;
};

export const ProductStatusChange = async (data) => {
  const response = await Api.put("product/statsupdate", data);
  return response.data;
};
