import * as Yup from "yup";

export const userSearchBarInitialValues = {
  is_paid: {
    label: "Both",
    value: null,
  },
  search: "",
};

export const userInitialValues = {
  profilePic: "",
  name: "",
  email: "",
  countryCode: "",
  mobileNo: "",
  password: "",
  status: true,
};

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const AddUserValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email format"
      )
      .required("Please enter email"),
    // countryCode: Yup.string()
    //   .matches(/[0-9]/, "Country code must be a number")
    //   .required("Please enter country code"),
    mobileNo: Yup.string().required("Please enter mobile number"),
    password: Yup.string()
      .required("Please enter password")

      .test(
        "uppderCase",
        "Must contain at least one uppercase character",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "lowerCase",
        "Must contain at least one lowercase character",
        (value) => /[a-z]/.test(value)
      )
      .test("oneNumber", "Must contain at least one number", (value) =>
        /[0-9]/.test(value)
      )
      .test(
        "oneSpecial",
        "Must contain at least one special character",
        (value) => /[!@#%&]/.test(value)
      )
      .min(8, "Must contain at least 8 characters"),
  })
  .defined();

export const EditUserValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter name"),
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email format"
      )
      .required("Please enter email"),
    // countryCode: Yup.string()
    //   .matches(/[0-9]/, "Country code must be a number")
    //   .required("Please enter country code"),
    mobileNo: Yup.string().required("Please enter mobile number"),
  })
  .defined();

export const UserSearchBarInitialValues = {
  search: "",
};
