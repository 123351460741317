import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "../../newstyle.css";
import { Link, useNavigate } from "react-router-dom";
import SearchBox from "../../../../Components/SearchBox";
import { Formik } from "formik";
import { UserSearchBarInitialValues } from "../../constant";
import { useQuery } from "@tanstack/react-query";
import {
  GetAdminUserListing,
  updateAdminStatus,
} from "../../../../Api/adminUser";
import {
  Confirmation,
  ErrorToast,
  SuccessToast,
} from "../../../../Utils/SweetAlert";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { useMutateDeleteUser } from "../../hooks/useApiHooks";
import Pagination from "../../../../Utils";
import { RiEditBoxLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import NameInitial from "../../../../Components/NameInitial/Nameinitial";
import ViewUserDetails from "../ViewAdminUser";
import { DeleteModal } from "../DeleteModal";

const AdminUserListing = () => {
  const formRef = useRef();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const handleDetailModal = () => {
    setOpen(!open);
  };

  const deleteUser = useMutateDeleteUser();

  const {
    isFetching: userDataLoading,
    data: userData,
    refetch,
  } = useQuery({
    queryKey: ["get-user-list", limit, page],
    queryFn: () =>
      GetAdminUserListing({
        limit: limit,
        page: page === 0 ? 1 : page + 1,
        search: formRef?.current?.values?.search,
      }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const handlePageChange = async (e, pageNew) => {
    setPage(pageNew);
    refetch();
  };

  const handleLimitChange = async (event) => {
    setPage(0);
    setLimit(parseInt(event.target.value, 10));
    refetch();
  };

  const handleDeleteRole = (userId) => {
    const reqData = { id: userId };

    DeleteModal("Are you sure you want to delete User?").then(
      async (deleteConfirm) => {
        if (deleteConfirm.isConfirmed) {
          deleteUser.mutate(reqData, {
            onSuccess: (response) => {
              if (response?.status != 0) {
                SuccessToast(response?.message || "Delete Successful.");
                refetch();
              } else {
                ErrorToast(response?.message || "Server Error.");
              }
            },
            onError: (error) => {
              ErrorToast(error?.response?.data?.message || "Server Error.");
            },
          });
        }
      }
    );
  };

  const handleConfirmRequest = (values) => {
    Confirmation(
      values?.status === true
        ? "Are you sure you want to active user status ?"
        : "Are you sure you want to inactive user status?"
    ).then(async (deleteConfirm) => {
      if (deleteConfirm.isConfirmed) {
        await updateAdminStatus(values)
          .then((res) => {
            refetch();
            SuccessToast(res?.data?.message || "Status Updated");
          })
          .catch((err) => {
            if (typeof err?.response?.data?.message !== "undefined") {
              ErrorToast(err?.response?.data?.message || "Server Error!!");
            } else {
              ErrorToast(err?.message || "Server Error!!");
            }
          });
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>OVE | Admin User</title>
      </Helmet>
      <div className="page-wrapper admin-user">
        <div className="content container-fluid">
          <div className="admin-wrapper">
            <div className="page-header">
              <div className="col">
                <h1 className="page-title">Admin User</h1>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link
                      to="/admin/dashboard"
                      className="text-color text-decoration-underline"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="sub-title">Admin User</li>
                </ul>
              </div>
              <div className="col-md-4">
                <Formik
                  initialValues={UserSearchBarInitialValues}
                  innerRef={formRef}
                >
                  {() => {
                    return (
                      <form>
                        <SearchBox refetch={refetch} setPage={setPage} />
                      </form>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-auto add-text">
                <Link
                  to="/admin/add-admin-user"
                  className="button  button-them-gray admin-btn"
                >
                  <i className="fas fa-plus " /> Add User
                </Link>
              </div>
            </div>
            <div className="col-sm-12 table-width">
              <div className="cards-table card-table">
                <div className="card-body">
                  <div className="table-responsive product-table custom-pagination">
                    <table className="table table<img<image-hover datatable table-manager">
                      <thead className="thead-light">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>E-mail</th>
                          <th>Phone Number</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userDataLoading && (
                          <tr>
                            <td align="center" colSpan={8}>
                              <div className="loader-wraper">
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                />
                              </div>
                            </td>
                          </tr>
                        )}
                        {!userDataLoading && userData !== undefined && (
                          <>
                            {userData?.data?.data.map((item, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={
                                    item?.role === "superadmin"
                                      ? "table disable-full-row"
                                      : "table"
                                  }
                                >
                                  <td>
                                    {item.profilePicUrl ? (
                                      <img
                                        src={item?.profilePicUrl}
                                        alt="img"
                                        className="list-image-news"
                                      />
                                    ) : (
                                      <NameInitial
                                        name={item.name}
                                        type="image"
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {" "}
                                    <Tooltip title={item.name} placement="top">
                                      <span>
                                        {item.name?.length >= 45
                                          ? item.name.substring(0, 40) + "..."
                                          : item.name || "-"}
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td>
                                    <Tooltip title={item.email} placement="top">
                                      <span>
                                        {item.email?.length >= 45
                                          ? item.email.substring(0, 40) + "..."
                                          : item.email || "-"}
                                      </span>
                                    </Tooltip>
                                  </td>
                                  <td className="">
                                    {item?.countryCode
                                      ? `${item?.countryCode} ${item.mobileNo}`
                                      : item?.mobileNo || "-"}
                                  </td>
                                  <td className="">
                                    {item?.status == true ? (
                                      <button
                                        onClick={() =>
                                          handleConfirmRequest({
                                            id: item?._id,
                                            status:
                                              item?.status === true
                                                ? false
                                                : true,
                                          })
                                        }
                                        class="active-btn btn-success"
                                        disabled={
                                          item?.role === "superadmin"
                                            ? true
                                            : false
                                        }
                                      >
                                        Active
                                      </button>
                                    ) : (
                                      (
                                        <button
                                          onClick={() =>
                                            handleConfirmRequest({
                                              id: item?._id,
                                              status:
                                                item?.status === true
                                                  ? false
                                                  : true,
                                            })
                                          }
                                          class="inactive-btn btn-danger"
                                          disabled={
                                            item?.role === "superadmin"
                                              ? true
                                              : false
                                          }
                                        >
                                          Inactive
                                        </button>
                                      ) || "-"
                                    )}
                                  </td>

                                  <td className="user-td-padding-remove">
                                    <Tooltip
                                      title="Edit"
                                      className="btn btn-sm btn-white text-secondary insight-stickey-actions-btn "
                                      onClick={() =>
                                        navigate("/admin/edit-admin-user", {
                                          state: { id: item?._id },
                                        })
                                      }
                                      disabled={
                                        item?.role === "superadmin"
                                          ? true
                                          : false
                                      }
                                    >
                                      <IconButton>
                                        <RiEditBoxLine className="text-secondary icon-color  p-0" />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      title="Delete"
                                      className="btn btn-white"
                                      onClick={() =>
                                        handleDeleteRole(item?._id)
                                      }
                                      disabled={
                                        item?.role === "superadmin"
                                          ? true
                                          : false
                                      }
                                    >
                                      <IconButton>
                                        <MdDelete className="text-secondary  icon-color p-0" />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      title="View"
                                      className="btn btn-sm btn-white"
                                      onClick={() => {
                                        setDetail(item);
                                        handleDetailModal();
                                      }}
                                      disabled={
                                        item?.role === "superadmin"
                                          ? true
                                          : false
                                      }
                                    >
                                      <IconButton>
                                        <IoEye className="text-secondary icon-color p-0" />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                </tr>
                              );
                            })}
                            {userData?.data?.data?.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan={8}>
                                  No Records Found.
                                </td>
                              </tr>
                            )}
                          </>
                        )}

                        {open === true && (
                          <ViewUserDetails
                            onClose={handleDetailModal}
                            details={detail}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              totalItem={userData?.data?.totalRecords || 0}
              itemsPerPage={limit}
              page={
                !userData?.data?.totalRecords ||
                userData?.data?.totalRecords <= 0
                  ? 0
                  : page
              }
              handleChangePage={handlePageChange}
              handleChangeRowsPerPage={handleLimitChange}
              colSpan={6}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminUserListing;
