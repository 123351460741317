import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { GetCategoryOptions } from "../../Api/productVariations";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const MultiLevelSelect = ({ defaultValues, name, className }) => {
  const { setFieldValue } = useFormikContext();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const stateData = useLocation();

  const { id } = stateData?.state || "";

  // Helper function to filter out the category with the given id
  const filterCategories = (categories, id) => {
    return categories
      .filter((category) => category._id !== id)
      .map((category) => ({
        ...category,
        value: category._id,
        label: category.name,
        childCategories: category.childCategories
          ? filterCategories(category.childCategories, id)
          : [],
      }));
  };

  // Fetch category options using react-query
  const { data: categoryOptions, refetch } = useQuery({
    queryKey: ["get-category-option-list"],
    queryFn: () => GetCategoryOptions({ search: "" }), // Adjust as per your API endpoint
    select: (data) => filterCategories(data.data, id),
    throwOnError: true,
  });

  useEffect(() => {
    refetch();
  });

// const customStyles = {
//   control: (provided) => ({
//     ...provided,
//     backgroundColor: "#F7F5F5",
//     borderRadius: "10px",
    
//   }),
// };

  // Function to find the path from root to a category with a given ID
  const findPathToCategory = (categories, targetId) => {
    for (let category of categories) {
      if (category.value === targetId) {
        return [category];
      }
      if (category.childCategories) {
        const path = findPathToCategory(category.childCategories, targetId);
        if (path) {
          return [category, ...path];
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (defaultValues && categoryOptions && categoryOptions.length > 0) {
      const path = findPathToCategory(categoryOptions, defaultValues);
      if (path) {
        setSelectedOptions(path);
        setFieldValue(name, path[path.length - 1].value);
      }
    }else{
      setSelectedOptions([]);
    }
  }, [defaultValues, categoryOptions, name, setFieldValue]);

  // Handle change in select option
  const handleChange = (level, selectedOption) => {
    let newSelectedOptions = [...selectedOptions.slice(0, level)];
    if (selectedOption) {
      newSelectedOptions.push(selectedOption);
    }
    setSelectedOptions(newSelectedOptions);

    // Update formik field value to selected option's _id
    let selectedValue = "";
    if (selectedOption) {
      selectedValue = selectedOption.value || "";
    } else if (newSelectedOptions?.length) {
      selectedValue =
        newSelectedOptions[newSelectedOptions?.length - 1]?._id || "";
      // console.log(selectedValue,"selecting")
    }
    setFieldValue(name, selectedValue);
  };

  // Render select component for each level
  const renderSelect = (level, options) => {
    return (
      <Select
        key={level}
        value={selectedOptions[level] || null}
        onChange={(selectedOption) => handleChange(level, selectedOption)}
        options={options}
        placeholder="Select..."
        isClearable
        styles={{
          option: (styles, { isDisabled }) => ({
            ...styles,
            cursor: isDisabled ? "not-allowed" : "pointer",
            marginTop: "0px",
            marginBottom: "0px",
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: "#F7F5F5",
            borderRadius: "10px",
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: "#F7F5F5",
            borderRadius: "10px",
            overflow: "hidden",
          }),
        }}
      />
    );
  };

  // Recursively render selects for each level of categories
  const renderSelects = (categories, level = 0) => {
    if (!categories || categories.length === 0) return null;

    return (
      <div key={level} className="my-2">
        {renderSelect(level, categories)}
        {selectedOptions[level] &&
          selectedOptions[level].childCategories &&
          renderSelects(selectedOptions[level].childCategories, level + 1)}
      </div>
    );
  };

  return (
    <div className={className}>
      {(categoryOptions && renderSelects(categoryOptions)) || (
        <div className="my-2">
          <Select
            key={0}
            value={selectedOptions[0] || null}
            onChange={(selectedOption) => handleChange(0, selectedOption)}
            options={categoryOptions}
            placeholder="Select..."
            isClearable
            styles={{
              option: (styles, { isDisabled }) => ({
                ...styles,
                cursor: isDisabled ? "not-allowed" : "pointer",
                marginTop: "0px",
                marginBottom: "0px",
              }),
              control: (styles) => ({
                ...styles,
                backgroundColor: "#F7F5F5",
                borderRadius: "10px",
              }),
              menu: (styles) => ({
                ...styles,
                backgroundColor: "#F7F5F5",
                borderRadius: "10px",
                overflow: "hidden",
              }),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MultiLevelSelect;
