import * as Yup from 'yup';



export const SymptomIntialValues = {
  name: '',
  isOnboarded: true,
};




export const SymptomValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required('Please enter name'),
    
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: '',
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const SymptomSearchBarInitialValues = {
  search: '',
};
