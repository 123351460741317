import Swal from 'sweetalert2';
import "./deleteModal.css";

export const DeleteModal = (message) =>
    
    Swal.fire({
        icon: 'warning',
        html: `<h3 class='custom-title'>${message}</h3>`,
        buttons: true,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Delete',
        confirmButtonColor: '#AD5ADC',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#FFFFFF',
        customClass: {
            icon: 'custom-icon',
            cancelButton: 'custom-cancel-button',
            confirmButton: 'custom-delete-button',
        },
        reverseButtons: true,
    });