import { useMutation } from '@tanstack/react-query';
import { CreateCategory, DeleteCategory, UpdateCategory } from '../../../Api/category';

export const useMutateCreateCategory = () =>
  useMutation({
    mutationFn: (data) => CreateCategory(data),
  });

export const useMutateEditCategory = () =>
  useMutation({
    mutationFn: (data) => UpdateCategory(data),
  });


export const useMutateDeleteCategory = () =>
  useMutation({
    mutationFn: (data) => DeleteCategory(data),
  });
