import Api from "../Utils/Axios";

export const postLogin = async (data) => {
  const response = await Api.post("auth/login", data);
  return response.data;
};

export const ForgotPasswordService = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.post("auth/forgotPassword", data));
    } catch (error) {
      reject(error);
    }
  });
};

export const ResetPasswordService = async (data, token) => {
  return new Promise((resolve, reject) => {
    try {
      resolve(
        Api.post("auth/resetPassword", data, {
          headers: { token: token },
        })
      );
    } catch (error) {
      reject(error);
    }
  });
};

export const UserLogout = async () => {
  const response = await Api.get("auth/logout");
  return response.data;
};
