import React, { useEffect, useRef, useState } from 'react';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';

const AdminLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };
  return (
    <>
      <div className='header header-one'>
        <AdminHeader onClick={toggleSidebar} />
      </div>
      <div className='sidebar pt-3' id='sidebar' ref={sidebarRef} style={openSidebar === true ? { marginLeft: '0px' } : null} >
        <AdminSidebar
          handleOnClick={toggleSidebar}
        />
      </div>

      {/* <footer className="text-center text-lg-start" style={{ bottom: 0,position: 'absolute', width: '90%' }}>
      <div className="text-center p-2">
        Powered by Alsaffron System.
      </div>
      </footer> */}
    </>
  );
};
export default AdminLayout;
