import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { AdminLayout } from '../Components/Layout';
import PrivateRoute from './PrivateRoute';
import { useEffect } from 'react';
import { ProductVariationsList, AddProductVariations, EditProductVariations } from '../Pages/ProductVariations';
import { AdminUserListing, ViewAdminUser, AddAdminUser, EditAdminUser } from '../Pages/AdminUser';
import ProductsList from '../Pages/Products/components/ProductList';
import AddProduct from '../Pages/Products/components/AddProduct';
import EditProduct from '../Pages/Products/components/EditProduct';
import CategoryList from '../Pages/Category/components/CategoryList';
import AddCategory from '../Pages/Category/components/AddCategory';
import EditCategory from '../Pages/Category/components/EditCategory';
// import FlowList from '../Pages/Flow/components/FlowList';
// import AddFlow from '../Pages/Flow/components/AddFlow';
// import EditFlow from '../Pages/Flow/components/EditFlow';
import Dashboard from '../Pages/Dashboard';
import GuideList from '../Pages/GuideProduct/components/GuideList';
import AddGuide from '../Pages/GuideProduct/components/AddGuide';
import EditGuide from '../Pages/GuideProduct/components/EditGuide';
import ClientUserListing from '../Pages/ClientUser/components/ClientUserListing';
import WelfareList from '../Pages/Welfare/components/WelfareList';
import EditWelfare from '../Pages/Welfare/components/EditWelfare';
import { AddDiscover, DiscoverDetail, DiscoverListing, EditDiscover } from '../Pages/Discover';
import Setting from '../Pages/Setting';
import { ProductDetail } from '../Pages/Products';
import GuideDetail from '../Pages/GuideProduct/components/GuideDetail';
import EmojiList from '../Pages/Emoji/components/EmojiList';
import AddEmoji from '../Pages/Emoji/components/AddEmoji';
import EditEmoji from '../Pages/Emoji/components/EditEmoji';
import AppNotificationList from '../Pages/AppNotification/components/NotificationsList';
import AddAppNotifications from '../Pages/AppNotification/components/AddNotifications';
import EditAppNotifications from '../Pages/AppNotification/components/EditNotifications';
import SymptomList from '../Pages/Symptom/components/SymptomList';
import AddSymptom from '../Pages/Symptom/components/AddSymptom';
import EditSymptom from '../Pages/Symptom/components/EditSymptom';
import FirebaseNotificationList from '../Pages/FirebaseNotification/components/FirebasNotificationsList';
import AddFirebaseNotifications from '../Pages/FirebaseNotification/components/AddFirebaseNotifications';
import EditFirebaseNotifications from '../Pages/FirebaseNotification/components/EditFirebaseNotifications';
import AddWelfare from '../Pages/Welfare/components/AddWelfare';
import Logout from '../Components/Logout';
import WelfareDetail from '../Pages/Welfare/WelfareDetail';

{/**
  const Logout = () => {
  const navigation = useNavigate();
  useEffect(() => {
    localStorage.removeItem('accessToken');
    navigation('/');
  }, []);
};
  */}

const AdminRoutes = () => {
  return (
    <React.Fragment>
      <AdminLayout />
      <Routes>
        <Route exact path='/dashboard' element={<PrivateRoute Component={Dashboard} />} />

        {/* <Route exact path='/product-variations' element={<PrivateRoute Component={ProductVariationsList} />} />
        <Route exact path='/add-product-variations' element={<PrivateRoute Component={AddProductVariations} />} />
        <Route exact path='/edit-product-variations' element={<PrivateRoute Component={EditProductVariations} />} /> */}

        <Route exact path='/product-listing' element={<PrivateRoute Component={ProductsList} />} />
        <Route exact path='/add-product' element={<PrivateRoute Component={AddProduct} />} />
        <Route exact path='/edit-product' element={<PrivateRoute Component={EditProduct} />} />
        <Route exact path='/product-detail' element={<PrivateRoute Component={ProductDetail} />} />

        <Route exact path='/admin-user-listing' element={<PrivateRoute Component={AdminUserListing} />} />
        <Route exact path='/add-admin-user' element={<PrivateRoute Component={AddAdminUser} />} />
        <Route exact path='/edit-admin-user' element={<PrivateRoute Component={EditAdminUser} />} />
        <Route exact path='/view-admin-user' element={<PrivateRoute Component={ViewAdminUser} />} />

        {/* <Route exact path='/client-user-listing' element={<PrivateRoute Component={ClientUserListing} />} /> */}

        <Route exact path='/category-listing' element={<PrivateRoute Component={CategoryList} />} />
        <Route exact path='/add-category' element={<PrivateRoute Component={AddCategory} />} />
        <Route exact path='/edit-category' element={<PrivateRoute Component={EditCategory} />} />

        {/* <Route exact path='/flow-listing' element={<PrivateRoute Component={FlowList} />} />
        <Route exact path='/add-flow' element={<PrivateRoute Component={AddFlow} />} />
        <Route exact path='/edit-flow' element={<PrivateRoute Component={EditFlow} />} /> */}

        {/* <Route exact path='/guide-listing' element={<PrivateRoute Component={GuideList} />} />
        <Route exact path='/add-guide' element={<PrivateRoute Component={AddGuide} />} />
        <Route exact path='/edit-guide' element={<PrivateRoute Component={EditGuide} />} />
        <Route exact path='/guide-detail' element={<PrivateRoute Component={GuideDetail} />} /> */}

        {/* Welfare */}

        {/* <Route exact path='/welfare-listing' element={<PrivateRoute Component={WelfareList} />} />
        <Route exact path='/add-welfare' element={<PrivateRoute Component={AddWelfare} />} />
        <Route exact path='/edit-welfare' element={<PrivateRoute Component={EditWelfare} />} />
        <Route exact path='/welfare-detail' element={<PrivateRoute Component={WelfareDetail} />} /> */}


        {/* <Route exact path='/emoji' element={<PrivateRoute Component={EmojiList} />} />
        <Route exact path='/add-emoji' element={<PrivateRoute Component={AddEmoji} />} />
        <Route exact path='/edit-emoji' element={<PrivateRoute Component={EditEmoji} />} /> */}

        {/* <Route exact path='/app-notifications' element={<PrivateRoute Component={AppNotificationList} />} />
        <Route exact path='/add-app-notification' element={<PrivateRoute Component={AddAppNotifications} />} />
        <Route exact path='/edit-app-notifications' element={<PrivateRoute Component={EditAppNotifications} />} /> */}

        {/* <Route exact path='/symptom' element={<PrivateRoute Component={SymptomList} />} />
        <Route exact path='/add-symptom' element={<PrivateRoute Component={AddSymptom} />} />
        <Route exact path='/edit-symptom' element={<PrivateRoute Component={EditSymptom} />} /> */}

        {/* <Route exact path='/firebase-notifications' element={<PrivateRoute Component={FirebaseNotificationList} />} />
        <Route exact path='/add-firebase-notification' element={<PrivateRoute Component={AddFirebaseNotifications} />} />
        <Route exact path='/edit-firebase-notifications' element={<PrivateRoute Component={EditFirebaseNotifications} />} /> */}

        {/* Discover */}
        {/* <Route exact path='/discover-listing' element={<PrivateRoute Component={DiscoverListing} />} />
        <Route exact path='/add-discover' element={<PrivateRoute Component={AddDiscover} />} />
        <Route exact path='/edit-discover' element={<PrivateRoute Component={EditDiscover} />} />
        <Route exact path='/discover-detail' element={<PrivateRoute Component={DiscoverDetail} />} /> */}

        <Route exact path='/setting' element={<PrivateRoute Component={Setting} />} />

        <Route exact path='*' element={<PrivateRoute Component={Dashboard} />} />

        <Route exact path='/logout' element={<Logout />} />

        {/* <Route exact path='/logout' element={<Logout />} /> */}
      </Routes>
    </React.Fragment>
  );
};
export default AdminRoutes;
