import React from 'react';
import { ErrorMessage, useFormikContext } from 'formik';

const SettingTextArea = ({ field, placeholder, Label, require }) => {
    const { handleChange } = useFormikContext();

    return (
        <div className='form-group'>
            <label className=''>
                {Label} {require !== false && <span className='text-danger'>*</span>}
            </label>
            <textarea
                className='input-customize'
                {...field}
                value={field.value}
                onChange={handleChange}
                name={field.name}
                placeholder={placeholder}
                style={{height:"250px" }}
            />
            <ErrorMessage name={field.name} component='div' className='text-danger' />
        </div>
    );
};

export default SettingTextArea;


