import { useMutation } from '@tanstack/react-query';
import { CreateAdminUser, DeleteAdminUser, UpdateAdminUser } from '../../../Api/adminUser';

export const useMutateCreateUser = () =>
  useMutation({
    mutationFn: (data) => CreateAdminUser(data),
  });

export const useMutateEditAdminUser = () =>
  useMutation({
    mutationFn: (data) => UpdateAdminUser(data),
  });

export const useMutateDeleteUser = () =>
  useMutation({
    mutationFn: (data) => DeleteAdminUser(data),
  });