// import React, { useEffect, useState } from 'react';
// import { IoIosCloseCircle, IoIosSearch } from "react-icons/io";
// import { useFormikContext } from 'formik';
// import {search} from '../../assets/Images/search.png'

// const SearchBox = ({ refetch, setPage }) => {
//     const { values, setFieldValue } = useFormikContext();
//     const [timer, setTimer] = useState(null);
//     const [searchValue, setSearchValue] = useState('');

//     useEffect(() => {
//         if (values) {
//             refetch();
//         }
//     }, [values, refetch]);

//     const handleSearchInputChange = (e) => {
//         const value = e.target.value;
//         if (value) {
//             setPage(0);
//         }
//         clearTimeout(timer);

//         const Timer = setTimeout(() => {
//             if (value.length > 2) {
//                 setFieldValue('search', value);
//             } else {
//                 setFieldValue('search', "");
//             }
//         }, 400);

//         setSearchValue(value);
//         if (value.length >= 2) {
//             setTimer(Timer);
//         }
//     };

//     const handleClearSearch = () => {
//         setSearchValue('');
//         setFieldValue('search', '');
//         clearTimeout(timer);
//     };

//     return (
//         <div className=''>
//             <div className='position-relative'>
//                 {/* <label className='d-block'>Search</label> */}
//                 <input
//                     className='searchbar-custom'
//                     type='text'
//                     name='search'
//                     value={searchValue}
//                     onChange={(e) => {
//                         handleSearchInputChange(e);
//                     }}
//                     placeholder='search here'
//                 />
//                  {searchValue ? (
//         <IoIosCloseCircle className="searchbar-icon" onClick={handleClearSearch} />
//       ) : (
//        search
//       )}
//                 {/* {searchValue ? <IoIosCloseCircle className='searchbar-close-icon' onClick={handleClearSearch} /> : ''} */}

//             </div>
//         </div>
//     );
// };

// export default SearchBox;

import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { useFormikContext } from "formik";
import searchImg from "../../assets/Images/search.png";

const SearchBox = ({ refetch, setPage }) => {
  const { values, setFieldValue } = useFormikContext();
  const [timer, setTimer] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (values) {
      refetch();
    }
  }, [values, refetch]);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    if (value) {
      setPage(0);
    }
    clearTimeout(timer);

    const Timer = setTimeout(() => {
      if (value.length > 2) {
        setFieldValue("search", value);
      } else {
        setFieldValue("search", "");
      }
    }, 400);

    setSearchValue(value);
    if (value.length >= 2) {
      setTimer(Timer);
    }
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setFieldValue("search", "");
    clearTimeout(timer);
  };

  return (
    <div className="searchbar-container">
      <div className="position-relative">
        <input
          className="searchbar-custom"
          type="text"
          name="search"
          value={searchValue}
          onChange={handleSearchInputChange}
          placeholder="Search here"
        />
        {searchValue ? (
          // <IoIosCloseCircle className='searchbar-icon' onClick={handleClearSearch} />
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="30px"
            width="30px"
            xmlns="http://www.w3.org/2000/svg"
            className='searchbar-icon' 
            onClick={handleClearSearch}
          >
            <path d="M256 48C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48zm52.7 283.3L256 278.6l-52.7 52.7c-6.2 6.2-16.4 6.2-22.6 0-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3l52.7-52.7-52.7-52.7c-3.1-3.1-4.7-7.2-4.7-11.3 0-4.1 1.6-8.2 4.7-11.3 6.2-6.2 16.4-6.2 22.6 0l52.7 52.7 52.7-52.7c6.2-6.2 16.4-6.2 22.6 0 6.2 6.2 6.2 16.4 0 22.6L278.6 256l52.7 52.7c6.2 6.2 6.2 16.4 0 22.6-6.2 6.3-16.4 6.3-22.6 0z"></path>
          </svg>
        ) : (
          <img src={searchImg} alt="search icon" className="searchbar-icon" />
        )}
      </div>
    </div>
  );
};

export default SearchBox;
