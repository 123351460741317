import * as Yup from "yup";

export const UpdateVersionIntialValues = {
  minimumIosVersion: "",
  minimumAndroidVersion: "",
};

// export const UpdateVersionValidationSchema = Yup.object()
//   .shape({
//     minimumIosVersion: Yup.string().required("Please enter ios version"),
//     minimumAndroidVersion: Yup.string().required(
//       "Please enter android version"
//     ),
//   })
//   .defined();




// Regular expression to validate positive decimal numbers
const positiveNonZeroNumberRegex = /^[1-9]\d*$/;

export const UpdateVersionValidationSchema = Yup.object({
  minimumIosVersion: Yup.string()
    .matches(positiveNonZeroNumberRegex, "Please enter a valid positive version number")
    .required("Please Enter IOS Version"),
  minimumAndroidVersion: Yup.string()
    .matches(positiveNonZeroNumberRegex, "Please enter a valid positive version number")
    .required("Please Enter Android Version"),
}).defined();
