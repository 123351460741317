import Api from '../Utils/Axios';

export const GetCategoryList = async (data) => {
  const response = await Api.get('category/', {params:data});
  return response.data;
};

export const CreateCategory = async (data) => {
  const response = await Api.post('category/', data);
  return response.data;
};

export const DeleteCategory = async (data) => {
  const response = await Api.delete(`category/${data}`);
  return response.data;
};

export const GetSingleCategory = async ({ id }) => {
  const response = await Api.get(`category/${id}`);
  return response.data;
};

export const UpdateCategory = async (data) => {
  const response = await Api.put('category/', data);
  return response.data;
};

export const GetGuideOptions = async (data) => {
  const response = await Api.get('guideProduct/list', data);
  return response.data;
};