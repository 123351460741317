
import React from 'react';
import AdminDetailModal from '../DetailModal';
import "./viewAdmin.css";
import NameInitial from '../../../../Components/NameInitial/Nameinitial';


const ViewUserDetails = ({ onClose, details }) => {
  
  return (
    <AdminDetailModal show size='lg' onClose={onClose} title='Admin Details'>
      <div className='row'>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <div className='mb-3'>
            {details?.profilePicUrl ? <img src={details?.profilePicUrl} alt={details?.name} className='admin-profile' /> :
              <div>
                <NameInitial
                  name={details?.name}
                  type="image"
                  style={{ width: "95%", height: "260px", borderRadius: "10px" }}
                />
              </div>
            }
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <div className='mb-3'>
            <div className='text-dark fw-bold'>Name</div>
            <p className='admin-content'>{details?.name || '-'}</p>
          </div>
          <div className='mb-3'>
            <div className='text-dark fw-bold'>Email</div>
            <p className='admin-content'>{details?.email || '-'}</p>
          </div>
          <div className='mb-3'>
            <div className='text-dark fw-bold'>Mobile Number</div>
            <p className='admin-content'>{details?.countryCode ? `${details?.countryCode} ${details?.mobileNo}` : details?.mobileNo || '-'}</p>
          </div>
          <div className='mb-3 d-flex'>
            <div className='text-dark fw-bold'>Status: </div>
            <p className='ms-2'>{details?.status === true ? <span class="d-block active-badge">Active</span> : <span class="d-block inactive-badge">Inactive</span>}</p>
          </div>
        </div>
      </div>
    </AdminDetailModal>
  );
};

export default ViewUserDetails;
