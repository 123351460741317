import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  GetDashboardCounters,
  GetDashboardRegisterUserChart,
  GetPopularDiscovers,
} from "../../Api/dashboard";
import { ErrorToast } from "../../Utils/SweetAlert";
import noImage from "../../assets/Images/no-image.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import HelpIcon from "@mui/icons-material/Help";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SearchIcon from "@mui/icons-material/Search";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Dashboard = () => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const [analyticsPeriod, setAnalyticsPeriod] = useState("month");

  const handleChange = (event) => {
    setAnalyticsPeriod(event.target.value);
  };

  const navigate = useNavigate();
  // const item = ""

  const { data: counters, isFetching: countersLoading } = useQuery({
    queryKey: ["get-counters-dashboard"],
    queryFn: () => GetDashboardCounters(),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const options = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: false,
        text: "Users",
      },
    },
  });

  const {
    data: chartData,
    isFetching: registerUserChartLoading,
    refetch: refetchChart,
  } = useQuery({
    queryKey: ["dashboard-register-user-chart"],
    queryFn: () => GetDashboardRegisterUserChart(analyticsPeriod),
    select: (response) => {
      let labels = [];
      let values = [];
      for (const item of response?.data) {
        labels.push(item?.label);
        values.push(item?.count);
      }

      return {
        labels,
        datasets: [
          {
            label: "Users",
            data: values,
            backgroundColor: "#AD5ADC",
          },
        ],
      };
    },
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const { data: popularDiscovers, isFetching: discoverLoading } = useQuery({
    queryKey: ["get-dashboard-popular-discovers"],
    queryFn: () => GetPopularDiscovers(),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  const handleImageError = (event) => {
    event.target.src = noImage;
  };

  useEffect(() => {
    refetchChart();
  }, [analyticsPeriod]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Ove | Admin Dashboard</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/admin-user-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <ManageAccountsIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Admin Users
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h4">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.user_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/client-user-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <PeopleIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Client Users
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h4">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.client_user_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/guide-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <HelpIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Guide Products
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.guide_product_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/category-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <CategoryIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Categories
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.category_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/product-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <InventoryIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Products
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.product_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/product-variations"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <ViewCarouselIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Product Variations
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.product_variations_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/discover-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <SearchIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Discovers
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.discover_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/welfare-listing"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <VolunteerActivismIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Welfares
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.welfare_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link to="/admin/emoji" className="dashboard-card-text-link">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <EmojiEmotionsIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Emoji
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.emoji_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/app-notifications"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <NotificationsIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          App Notification
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.app_notification_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link to="/admin/symptom" className="dashboard-card-text-link">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <HealthAndSafetyIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Symptom
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.symptom_count || 0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            {/* <div className="col-xl-4 col-md-6 col-sm-4 col-12">
              <Link
                to="/admin/firebase-notifications"
                className="dashboard-card-text-link"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon bg-pareto-theme-black">
                        <CircleNotificationsIcon fontSize="30px" />
                      </span>
                      <div className="dash-count">
                        <div className="dash-title dashboard-card-text-link">
                          Firebase Notification
                        </div>
                        <div className="dash-counts input-label">
                          <td className="h5">
                            {countersLoading
                              ? "Loading..."
                              : counters?.data?.firebase_notification_count ||
                              0}
                          </td>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 col-xl-12 col-md-12 col-sm-12">
              <div className="card flex-fill border-card">
                <div className="card-header custom-card-header">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title">Users</h5>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">
                          Period
                        </InputLabel> */}
                        <Select
                          labelId="demo-simple-select-label"
                          value={analyticsPeriod}
                          onChange={handleChange}
                        >
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="week">This Week</MenuItem>
                          <MenuItem value="month">Monthly</MenuItem>
                          <MenuItem value="year">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                </div>
                <div className="card-body">
                  {registerUserChartLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <Bar options={options} data={chartData} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-xl-12 col-md-12 col-sm-12 table-width">
            <div className="table-width">
              <h2 className="page-title">Popular Discovers</h2>
              <div className="row">
                <div className="col-12 col-xl-12 col-md-12 col-sm-12">
                  <div className="custom-popularcard card-table">
                    <div className="table-responsive custom-pagination">
                      <table className="table table-center table<img<image-hover datatable table-manager">
                        <thead className="thead-light">
                          <tr>
                            <th className="text">Icon</th>
                            <th className="text">Title</th>
                            <th className="text">Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {discoverLoading && (
                            <tr>
                              <td align="center" colSpan={3}>
                                <div className="loader-wraper">
                                  <div
                                    className="spinner-border text-light"
                                    role="status"
                                  />
                                </div>
                              </td>
                            </tr>
                          )}
                          {!discoverLoading &&
                            popularDiscovers !== undefined && (
                              <>
                                {popularDiscovers?.data?.map((item, index) => {
                                  return (
                                    <tr key={index} className="table">
                                      <td className="user-td-padding-remove">
                                        <img
                                          width={50}
                                          height={50}
                                          alt={item.name}
                                          src={item?.icon}
                                          onError={handleImageError}
                                        />
                                      </td>
                                      <td className="user-td-padding-remove">
                                        {item?.title}
                                      </td>
                                      <td className="user-td-padding-remove text-capitalize">
                                        {item?.type?.replace("_", " ") || "A - Z"}
                                      </td>
                                    </tr>
                                  );
                                })}
                                {popularDiscovers?.data?.length === 0 && (
                                  <tr>
                                    <td align="center" colSpan={3}>
                                      No Records Found.
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
