import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductIntialValues, ProductValidationSchema } from "../../constant";
import {
  useMutateCreateImage,
  useMutateEditProduct,
} from "../../hooks/useApiHook";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import {
  GetCategoryOptions,
  GetFlowOptions,
  GetProductVariationsOptions,
} from "../../../../Api/productVariations";
import { useQuery } from "@tanstack/react-query";
import InputWithLabel from "../../../../Components/InputWithLabel";
import { GetSingleProduct } from "../../../../Api/product";
import Select from "react-select";
import TextArea from "../../../../Components/TextArea";
import MultiLevelSelect from "../../../../Components/MultiLevelSelectBox";
import { AiOutlineCloudUpload } from "react-icons/ai";

const EditProduct = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const imageInputRef = useRef([]);
  const imageContainerRef = useRef([]);
  const dropAreaRef = useRef([]);
  const stateData = useLocation();
  const { id } = stateData?.state || "";

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  // const [variations, setVariations] = useState([]);

  /**
   * Api
   */
  const editProductApi = useMutateEditProduct();
  const addImageApi = useMutateCreateImage();

  var variationsValueOptions = [];
  const variations = [];

  /**
   * Used to get product variation options
   */
  const {
    isFetching: isProductVariationOptionsLoading,
    data: productVariationOptions,
  } = useQuery({
    queryKey: ["get-product-variations-option"],
    queryFn: () => GetProductVariationsOptions({ search: "" }),
    select: (data) => [
      ...data.data.map((item) => ({
        ...item,
        value: item._id,
        label: item.name,
        variationValues: item?.variationValues?.map((opt) => ({
          ...opt,
          label: opt?.name,
          value: opt?._id,
        })),
      })),
    ],
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  /**
   * Used to get single product record
   */
  const { data } = useQuery({
    queryKey: ["get-single-product"],
    queryFn: () => GetSingleProduct({ id: id }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  /**
   * Used to get flow options
   */
  const {
    // isFetching: isFlowOptionsLoading,
    data: flowOptions,
  } = useQuery({
    queryKey: ["get-flow-option"],
    queryFn: () => GetFlowOptions({ search: "" }),
    select: (data) => [
      ...data?.data?.map((item) => ({
        ...item,
        value: item?._id,
        label: item?.name,
      })),
    ],
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server Error.");
    },
  });

  /**
   * Used to set form value
   */
  useEffect(() => {
    if (data) {
      if (formRef?.current?.values) {
        const setFieldValue = formRef.current.setFieldValue;
        formRef?.current?.setValues({
          name: data?.data?.name,
          links: data?.data?.links,
          category: data?.data?.category?._id,
          description: data?.data?.description || "",
          flow: data?.data?.flow?._id,
          count: data?.data?.count,
        });

        data?.data?.links?.forEach((item, index) => {
          setFieldValue(`links[${index}].url`, item.url);
          const images = item?.images?.map((img) => img?.url) || [];
          setFieldValue(`links[${index}].images`, images);
        });
      }
    }
  }, [data]);

  /**
   * Used to push variations options in array
   */
  useEffect(() => {
    variations?.forEach(function (element) {
      variationsValueOptions.push({
        ...element,
        label: element?.name,
        value: element?.id,
      });
    });
  }, [variationsValueOptions]);

  /**
   * Used to trigger file input
   * @param {number} index "Index of current ref"
   */
  const triggerFileInput = (index) => {
    imageInputRef.current[index].click();
  };

  /**
   * Used to get spliced image file name
   * @param {string} url "Image file url"
   * @returns "Returns spliced image file name"
   */
  function getLastNameFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  /**
   * Used to handle submit form
   * @param {any} values "Values of form fields"
   */
  const handleSubmitEditProductVariations = (values) => {
    const reqData = {
      id: id,
      name: values?.name || null,
      categoryId: values?.category || null,
      flowId: values?.flow || null,
      description: values?.description || null,
      count: values?.count || null,
      links: values?.links?.map((item) => {
        return {
          items: item?.items?.map((itemsVal) => {
            return {
              id: itemsVal?._id || null,
              variationId: itemsVal?.variationId || null,
              variationValueId: itemsVal?.variationValueId || null,
            };
          }),
          link: item?.link || null,
          amazonAffiliateLink: item?.amazonAffiliateLink || null,
          id: item?._id,
          images:
            item?.images.length > 0
              ? item?.images?.map((img) => {
                  if (typeof img === "string") {
                    return getLastNameFromUrl(img);
                  } else {
                    return img?.name;
                  }
                })
              : null,
          availablity: {
            Message: item?.availablity?.Message || null,
            Type: item?.availablity?.Type || null,
          },
          price: {
            amount: item?.price?.amount || null,
            currency: item?.price?.currency || null,
            displayAmount: item?.price?.displayAmount || null,
          },
        };
      }),
      status: data?.data?.status,
    };

    editProductApi.mutate(reqData, {
      onSuccess: (response) => {
        SuccessToast(response?.message || " Product updated.");
        navigate("/admin/product-listing");
      },
      onError: (error) => {
        ErrorToast(error?.response?.data?.message || "Server error.");
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>OVE | Admin Edit Products</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-border">
                <div className="card-body">
                  <div className="page-header d-block">
                    <div className="row">
                      <div className="col">
                        <h3 className="page-title">Edit Product</h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              className="text-color text-decoration-underline"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">
                            <Link to="/admin/product-listing">Product</Link>
                          </li>

                          <li className="breadcrumb-item sub-title">
                            Edit Product
                          </li>
                        </ul>
                      </div>
                      <div className="col-auto">
                        <button
                          onClick={() => navigate("/admin/product-listing")}
                          className="back-btn"
                        >
                          <i className="fas fa-angle-double-left" /> Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Formik
                      initialValues={ProductIntialValues}
                      validationSchema={ProductValidationSchema}
                      validateOnChange
                      onSubmit={handleSubmitEditProductVariations}
                      innerRef={formRef}
                    >
                      {(formik) => {
                        const { values, setFieldValue, errors, touched } =
                          formik;
                        return (
                          <Form>
                            <span className="infomation-general">
                              General Information
                            </span>
                            <section className="section-border">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-12">
                                      <Field
                                        Label="Product Name"
                                        type="text"
                                        name="name"
                                        placeholder="Enter Product Name"
                                        component={InputWithLabel}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <Field
                                        Label="Count"
                                        type="text"
                                        name="count"
                                        placeholder="Enter Product Count"
                                        component={InputWithLabel}
                                        require={false}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <label>
                                        Category{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <MultiLevelSelect
                                        name="category"
                                        defaultValues={values?.category}
                                      />
                                      <ErrorMessage
                                        name="category"
                                        component="div"
                                        className="text-danger"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <Field
                                    Label="Description"
                                    name="description"
                                    placeholder="Enter Description"
                                    component={TextArea}
                                    require={true}
                                  />
                                </div>
                              </div>
                            </section>
                            <div className="row">
                              <FieldArray name="links">
                                {({ push, remove }) => (
                                  <div>
                                    <div className="row d-flex justify-content-between my-2">
                                      <div className="col">
                                        <h4>Product variations</h4>
                                      </div>
                                    </div>
                                    {values.links.map((team, teamIndex) => (
                                      <section className="setting-pro-border">
                                        <div className="row  justify-content-end">
                                          <div className="col-auto text-end">
                                            <svg
                                              stroke="none"
                                              fill="#AD5ADC"
                                              stroke-width="32"
                                              viewBox="0 0 24 24"
                                              height="35px"
                                              width="35px"
                                              xmlns="http://www.w3.org/2000/svg"
                                              onClick={() =>
                                                remove({
                                                  link: "",
                                                  images: [],
                                                  items: [
                                                    {
                                                      variationId: "",
                                                      variationValueId: "",
                                                    },
                                                  ],
                                                })
                                              }
                                            >
                                              <g id="Circle_Remove">
                                                <g>
                                                  <path d="M9.525,13.765a.5.5,0,0,0,.71.71c.59-.59,1.175-1.18,1.765-1.76l1.765,1.76a.5.5,0,0,0,.71-.71c-.59-.58-1.18-1.175-1.76-1.765.41-.42.82-.825,1.23-1.235.18-.18.35-.36.53-.53a.5.5,0,0,0-.71-.71L12,11.293,10.235,9.525a.5.5,0,0,0-.71.71L11.293,12Z"></path>
                                                  <path d="M12,21.933A9.933,9.933,0,1,1,21.934,12,9.945,9.945,0,0,1,12,21.933ZM12,3.067A8.933,8.933,0,1,0,20.934,12,8.944,8.944,0,0,0,12,3.067Z"></path>
                                                </g>
                                              </g>
                                            </svg>
                                          </div>
                                        </div>
                                        <div key={teamIndex}>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Product Photo</label>
                                              <div
                                                // ref={dropAreaRef}
                                                onDrop={(event) => {
                                                  event.preventDefault();
                                                  dropAreaRef?.current[
                                                    teamIndex
                                                  ]?.classList?.remove(
                                                    "drag-over"
                                                  );
                                                  const files =
                                                    event.dataTransfer.files;

                                                  const formData =
                                                    new FormData();
                                                  for (
                                                    let i = 0;
                                                    i < files.length;
                                                    i++
                                                  ) {
                                                    formData.append(
                                                      "images",
                                                      files[i]
                                                    );
                                                  }

                                                  addImageApi.mutate(formData, {
                                                    onSuccess: async (
                                                      response
                                                    ) => {
                                                      const imgAry = [];
                                                      await values?.links[
                                                        teamIndex
                                                      ]?.images?.map((img) => {
                                                        imgAry?.push(img);
                                                      });
                                                      await response?.data?.map(
                                                        (item) => {
                                                          imgAry.push(item);
                                                        }
                                                      );
                                                      await setFieldValue(
                                                        `links[${teamIndex}].images`,
                                                        imgAry
                                                      );
                                                    },
                                                    onError: (error) => {
                                                      ErrorToast(
                                                        error?.response?.data
                                                          ?.message ||
                                                          "Server Error."
                                                      );
                                                    },
                                                  });
                                                }}
                                                onDragOver={(event) => {
                                                  event.preventDefault();
                                                  dropAreaRef?.current[
                                                    teamIndex
                                                  ]?.classList?.add(
                                                    "drag-over"
                                                  );
                                                }}
                                                onDragLeave={() => {
                                                  dropAreaRef?.current[
                                                    teamIndex
                                                  ]?.classList?.remove(
                                                    "drag-over"
                                                  );
                                                }}
                                                className="drop-area dotted-border"
                                                onClick={() =>
                                                  triggerFileInput(teamIndex)
                                                }
                                              >
                                                <div className="upload-icon">
                                                  <AiOutlineCloudUpload
                                                    size={24}
                                                  />
                                                </div>
                                                <p>
                                                  <span className="drop-area-text">
                                                    {" "}
                                                    Click to upload
                                                  </span>{" "}
                                                  or drag and drop
                                                </p>
                                                <input
                                                  type="file"
                                                  multiple
                                                  ref={(element) =>
                                                    (imageInputRef.current[
                                                      teamIndex
                                                    ] = element)
                                                  }
                                                  name={`links[${teamIndex}].images`}
                                                  accept="image/png, image/jpeg, image/jpg"
                                                  onChange={(event) => {
                                                    const files =
                                                      event.target.files;
                                                    const formData =
                                                      new FormData();
                                                    for (
                                                      let i = 0;
                                                      i < files.length;
                                                      i++
                                                    ) {
                                                      formData.append(
                                                        "images",
                                                        files[i]
                                                      );
                                                    }

                                                    addImageApi.mutate(
                                                      formData,
                                                      {
                                                        onSuccess: async (
                                                          response
                                                        ) => {
                                                          const imgAry = [];
                                                          await values?.links[
                                                            teamIndex
                                                          ]?.images?.map(
                                                            (img) => {
                                                              imgAry?.push(img);
                                                            }
                                                          );
                                                          await response?.data?.map(
                                                            (item) => {
                                                              imgAry.push(item);
                                                            }
                                                          );
                                                          await setFieldValue(
                                                            `links[${teamIndex}].images`,
                                                            imgAry
                                                          );
                                                        },
                                                        onError: (error) => {
                                                          ErrorToast(
                                                            error?.response
                                                              ?.data?.message ||
                                                              "Server error."
                                                          );
                                                        },
                                                      }
                                                    );
                                                  }}
                                                  className="file-input"
                                                />
                                              </div>

                                              <ErrorMessage
                                                name={`links[${teamIndex}].images`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>

                                            <div className="col-md-6">
                                              <Field
                                                Label="Product Link"
                                                type="text"
                                                name={`links[${teamIndex}].link`}
                                                placeholder="Enter product link"
                                                component={TextArea}
                                                className="custom-product-input"
                                              />
                                            </div>
                                            <div
                                              ref={(element) =>
                                                (imageContainerRef.current[
                                                  teamIndex
                                                ] = element)
                                              }
                                              className="image-container col-12"
                                            >
                                              {values?.links[teamIndex]?.images
                                                ?.length > 0 &&
                                                values?.links[
                                                  teamIndex
                                                ]?.images.map((url, index) => {
                                                  return (
                                                    <div
                                                      key={index}
                                                      className="image-wrapper"
                                                    >
                                                      <img
                                                        src={url}
                                                        className="preview-image"
                                                        alt={`uploaded ${url}`}
                                                      />
                                                      <button
                                                        className="remove-button"
                                                        type="button"
                                                        onClick={async () => {
                                                          const updatedImages =
                                                            values?.links[
                                                              teamIndex
                                                            ]?.images?.filter(
                                                              (item) =>
                                                                item !== url
                                                            );
                                                          setFieldValue(
                                                            `links[${teamIndex}].images`,
                                                            updatedImages
                                                          );
                                                        }}
                                                      >
                                                        X
                                                      </button>
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </div>

                                          <div>
                                            <FieldArray
                                              name={`links[${teamIndex}].items`}
                                            >
                                              {({
                                                push: pushMember,
                                                remove: removeMember,
                                              }) => (
                                                <div>
                                                  {team.items.map(
                                                    (member, memberIndex) => (
                                                      <div
                                                        key={memberIndex}
                                                        className="member-container"
                                                      >
                                                        <div className="flex-container">
                                                          <div className="select-container">
                                                            <label
                                                              htmlFor={`links[${teamIndex}].items[${memberIndex}].variationId`}
                                                            >
                                                              Product Type
                                                            </label>

                                                            <Select
                                                              name={`links[${teamIndex}].items[${memberIndex}].variationId`}
                                                              placeholder="Select variation type"
                                                              onMenuOpen={
                                                                handleDropdownOpen
                                                              }
                                                              onMenuClose={
                                                                handleDropdownClose
                                                              }
                                                              options={productVariationOptions?.map(
                                                                (item) => item
                                                              )}
                                                              onChange={(e) => {
                                                                setFieldValue(
                                                                  `links[${teamIndex}].items[${memberIndex}].variationId`,
                                                                  e.value
                                                                );
                                                              }}
                                                              value={productVariationOptions?.find(
                                                                (opt) => {
                                                                  if (opt) {
                                                                    if (
                                                                      opt?._id ===
                                                                      values
                                                                        ?.links[
                                                                        teamIndex
                                                                      ]?.items[
                                                                        memberIndex
                                                                      ]
                                                                        ?.variationId
                                                                    ) {
                                                                      return opt;
                                                                    }
                                                                  }
                                                                }
                                                              )}
                                                              styles={{
                                                                control: (
                                                                  styles
                                                                ) => ({
                                                                  ...styles,
                                                                  backgroundColor:
                                                                    "#F7F5F5",
                                                                  borderRadius:
                                                                    "10px",
                                                                }),
                                                                menu: (
                                                                  styles
                                                                ) => ({
                                                                  ...styles,
                                                                  backgroundColor:
                                                                    "#F7F5F5",
                                                                  maxHeight:
                                                                    "auto",
                                                                }),
                                                                option: (
                                                                  styles,
                                                                  {
                                                                    isSelected,
                                                                    isDisabled,
                                                                  }
                                                                ) => ({
                                                                  ...styles,
                                                                  cursor:
                                                                    isDisabled
                                                                      ? "not-allowed"
                                                                      : "pointer",
                                                                  marginTop:
                                                                    "0px",
                                                                  marginBottom:
                                                                    "0px",
                                                                  backgroundColor:
                                                                    isSelected
                                                                      ? "#E0E0E0"
                                                                      : "#F7F5F5",
                                                                }),
                                                              }}
                                                              menuPlacement="auto"
                                                            />
                                                          </div>

                                                          {values?.links[
                                                            teamIndex
                                                          ]?.items[memberIndex]
                                                            ?.variationId !==
                                                            "" && (
                                                            <div className="select-container">
                                                              <label
                                                                className="variations-margin-mobile"
                                                                htmlFor={`links[${teamIndex}].items[${memberIndex}].variationValueId`}
                                                              >
                                                                Product
                                                                variations value
                                                              </label>

                                                              <Select
                                                                name={`links[${teamIndex}].items[${memberIndex}].variationValueId`}
                                                                placeholder="Select variation value"
                                                                onMenuOpen={
                                                                  handleDropdownOpen
                                                                }
                                                                onMenuClose={
                                                                  handleDropdownClose
                                                                }
                                                                options={
                                                                  productVariationOptions !==
                                                                  undefined
                                                                    ? productVariationOptions?.find(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item?._id ===
                                                                          values
                                                                            ?.links[
                                                                            teamIndex
                                                                          ]
                                                                            ?.items[
                                                                            memberIndex
                                                                          ]
                                                                            ?.variationId
                                                                      )
                                                                        ?.variationValues
                                                                    : []
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setFieldValue(
                                                                    `links[${teamIndex}].items[${memberIndex}].variationValueId`,
                                                                    e?.value
                                                                  );
                                                                }}
                                                                value={
                                                                  productVariationOptions !==
                                                                  undefined
                                                                    ? productVariationOptions
                                                                        ?.find(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item?._id ===
                                                                            values
                                                                              ?.links[
                                                                              teamIndex
                                                                            ]
                                                                              ?.items[
                                                                              memberIndex
                                                                            ]
                                                                              ?.variationId
                                                                        )
                                                                        ?.variationValues?.find(
                                                                          (
                                                                            Vvalue
                                                                          ) =>
                                                                            Vvalue._id ===
                                                                            values
                                                                              ?.links[
                                                                              teamIndex
                                                                            ]
                                                                              ?.items[
                                                                              memberIndex
                                                                            ]
                                                                              ?.variationValueId
                                                                        )
                                                                    : []
                                                                }
                                                                styles={{
                                                                  control: (
                                                                    styles
                                                                  ) => ({
                                                                    ...styles,
                                                                    backgroundColor:
                                                                      "#F7F5F5",
                                                                    borderRadius:
                                                                      "10px",
                                                                  }),
                                                                  menu: (
                                                                    styles
                                                                  ) => ({
                                                                    ...styles,
                                                                    backgroundColor:
                                                                      "#F7F5F5",
                                                                    borderRadius:
                                                                      "10px",
                                                                    maxHeight:
                                                                      "auto",
                                                                  }),
                                                                  option: (
                                                                    styles,
                                                                    {
                                                                      isSelected,
                                                                      isDisabled,
                                                                    }
                                                                  ) => ({
                                                                    ...styles,
                                                                    cursor:
                                                                      isDisabled
                                                                        ? "not-allowed"
                                                                        : "pointer",
                                                                    marginTop:
                                                                      "0px",
                                                                    marginBottom:
                                                                      "0px",
                                                                    backgroundColor:
                                                                      isSelected
                                                                        ? "#E0E0E0"
                                                                        : "#F7F5F5",
                                                                    borderRadius:
                                                                      "10px",
                                                                  }),
                                                                }}
                                                                menuPlacement="auto"
                                                              />

                                                              <ErrorMessage
                                                                name={`links[${teamIndex}].items[${memberIndex}].variationValueId`}
                                                                component="div"
                                                                className="text-danger"
                                                              />
                                                            </div>
                                                          )}

                                                          <div className="button-container">
                                                            <button
                                                              className="custom-addveriation-button"
                                                              type="button"
                                                              onClick={() =>
                                                                pushMember({
                                                                  variationId:
                                                                    "",
                                                                  variationValueId:
                                                                    "",
                                                                })
                                                              }
                                                            >
                                                              Add Variations
                                                              Type
                                                            </button>

                                                            <svg
                                                              viewBox="0 0 512 512"
                                                              height="40px"
                                                              width="40px"
                                                              className="addveriation-remove"
                                                              onClick={() =>
                                                                removeMember(
                                                                  memberIndex
                                                                )
                                                              }
                                                            >
                                                              <path
                                                                stroke="#AD5ADC"
                                                                fill="none"
                                                                stroke-miterlimit="10"
                                                                strokeWidth="32"
                                                                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                                              ></path>
                                                              <path
                                                                fill="none"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                stroke="#AD5ADC"
                                                                strokeWidth="32"
                                                                d="M336 256H176"
                                                              ></path>
                                                            </svg>
                                                          </div>
                                                          {errors.links?.[
                                                            teamIndex
                                                          ]?.items &&
                                                            touched.links?.[
                                                              teamIndex
                                                            ]?.items &&
                                                            values.links?.[
                                                              teamIndex
                                                            ]?.items?.length ==
                                                              memberIndex +
                                                                1 && (
                                                              <div className="text-danger">
                                                                {
                                                                  errors.links[
                                                                    teamIndex
                                                                  ].items
                                                                }
                                                              </div>
                                                            )}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              )}
                                            </FieldArray>
                                          </div>
                                        </div>
                                      </section>
                                    ))}

                                    <div className="row my-3 justify-content-end">
                                      <div className="col-auto">
                                        <button
                                          className="text-end custom-addveriationsection-button"
                                          type="button"
                                          onClick={() =>
                                            push({
                                              link: "",
                                              images: [],
                                              items: [
                                                {
                                                  variationId: "",
                                                  variationValueId: "",
                                                },
                                              ],
                                            })
                                          }
                                        >
                                          Add Variations
                                        </button>
                                      </div>
                                    </div>
                                    {!values?.links?.length && (
                                      <ErrorMessage
                                        name="links"
                                        component="div"
                                        className="text-danger"
                                      />
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            </div>

                            <div className="text-center mt-3">
                              <button
                                type="submit"
                                className="add-category-button"
                              >
                                Edit Product
                              </button>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
