import Api from '../Utils/Axios';

export const GetDashboardCounters = async () => {
  const response = await Api.get('dashboard/counters/');
  return response.data;
};

export const GetPopularDiscovers = async () => {
  const response = await Api.get('dashboard/popularDiscovers/');
  return response.data;
};

export const GetDashboardRegisterUserChart = async (type) => {
  const response = await Api.get(`dashboard/registerUsersChart?type=${type}`);
  return response.data;
};