import * as Yup from 'yup';

export const AppNotificationIntialValues = {
  title: '',
  message:'',
  day:'',
};

export const AppNotificationValidationSchema = Yup.object()
  .shape({
    title: Yup.string().required('Please enter title'),
    message:Yup.string().required('Please enter message'),
    day: Yup.number()
    .typeError('Day must be a number')
    .required('Please enter a day')
    .min(1, 'Days must between 1 to 28')
    .max(28, 'Days must between 1 to 28')
  })
  .defined();

export const InsightSearchBarInitialValues = {
  search: '',
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const AppNotificationSearchBarInitialValues = {
  search: '',
};
