import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorToast, SuccessToast } from "../../../../Utils/SweetAlert";
import { useQuery } from "@tanstack/react-query";
import ImageViewer from "react-simple-image-viewer";
import { GetSingleProduct } from "../../../../Api/product";
import { MdOutlineCurrencyPound } from "react-icons/md";

const ProductDetail = () => {
  const navigate = useNavigate();
  const stateData = useLocation();

  const { id } = stateData?.state || "";

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const { data } = useQuery({
    queryKey: ["get-single-product"],
    queryFn: () => GetSingleProduct({ id: id }),
    throwOnError: (error) => {
      ErrorToast(error?.response?.data?.message || "Server error.");
    },
  });
  const link = data?.data?.links || [];

  useEffect(() => {
    if (data?.data?.links) {
      const imageUrls = data.data.links.reduce((images, linkData) => {
        linkData.images.forEach((imageData) => {
          images.push(imageData.url);
        });
        return images;
      }, []);
      setImages(imageUrls);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>OVE | Admin Product Detail</title>
      </Helmet>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-border">
                <div className="card-body">
                  <div className="page-header d-block">
                    <div className="row">
                      <div className="col">
                        <h3 className="page-title">Product Detail</h3>
                        <ul className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link
                              to="/admin/dashboard"
                              className="text-color text-decoration-underline"
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="breadcrumb-item sub-title">
                            <Link to="/admin/product-listing">Product</Link>
                          </li>

                          <li className="breadcrumb-item sub-title">
                            Product Details
                          </li>
                        </ul>
                      </div>
                      <div className="col-auto">
                        <button
                          onClick={() => navigate("/admin/product-listing")}
                          // className="button button-them-gray"
                          className="back-btn"
                        >
                          <i className="fas fa-angle-double-left" /> Back
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-12">
                          <label className="d-block modal-label">Name</label>
                          <p className="d-block modal-value detail-backg">
                            {data?.data?.name || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 speacing-filed">
                          <label className="d-block modal-label">
                            Category
                          </label>
                          <p className="d-block modal-value detail-backg">
                            {data?.data?.category?.name || "-"}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 speacing-filed">
                          <label className="d-block modal-label">Count</label>
                          <p className="d-block modal-value detail-backg">
                            {data?.data?.count || "-"}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label className="d-block modal-label">Description</label>
                      <div className="desc-detail">
                        <span className="d-block modal-value text-justify description-detail">
                          {data?.data?.description || "-"}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="heading-variations">
                    <p>Product variations</p>

                    {link?.map((linkData, linkIndex) => {
                      const hasValidVariations = linkData.items.some(
                        (itemData) => {
                          const productType = data?.data?.productVariation.find(
                            (variation) =>
                              variation?._id === itemData?.variationId
                          )?.name;

                          const productVariationValue =
                            data?.data?.productVariationValue.find(
                              (variationValue) =>
                                variationValue?._id ===
                                itemData?.variationValueId
                            )?.name;

                          return (
                            productType !== undefined &&
                            productVariationValue !== undefined
                          );
                        }
                      );

                      return (
                        <div className="section-border" key={linkIndex}>
                          {hasValidVariations && (
                            <div className="col-sm-12 table-width">
                              <div className="cards-table product-variantion card-table">
                                <div className="card-body">
                                  <div className="table-responsive pro-vari-table custom-pagination">
                                    <table className="table datatable table-manager">
                                      <thead className="thead-light">
                                        <tr>
                                          <th>Product Type</th>
                                          <th>Product Variations Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {linkData.items.map(
                                          (itemData, index) => {
                                            const productType =
                                              data?.data?.productVariation.find(
                                                (variation) =>
                                                  variation?._id ===
                                                  itemData?.variationId
                                              )?.name || "-";

                                            const productVariationValue =
                                              data?.data?.productVariationValue.find(
                                                (variationValue) =>
                                                  variationValue?._id ===
                                                  itemData?.variationValueId
                                              )?.name || "-";

                                            return (
                                              <tr key={index}>
                                                <td>{productType}</td>
                                                <td>{productVariationValue}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="speac">
                            <div className="row">
                              <div className="col-6">
                                <div className="d-flex align-items-center">
                                  <label className="modal-label mb-0 mr-2">
                                    Availability:
                                  </label>
                                  <span className=" col-8 modal-value availablity">
                                    {linkData.availablity?.Message || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="d-flex align-items-center">
                                  <label className="modal-label mb-0 mr-2">
                                    Price:
                                  </label>
                                  <span className=" col-4 modal-value availablity">
                                    {linkData?.price?.amount ? (
                                      <>
                                        <MdOutlineCurrencyPound />
                                        {linkData.price.amount}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="speac">
                            <div className="row">
                              <div className="col-12">
                                <label className="d-block modal-label">
                                  Product Link
                                </label>
                                <div>
                                  <a
                                    href={linkData?.link}
                                    target="_blank"
                                    className="d-block modal-value prolink-detail"
                                  >
                                    {linkData?.link || "-"}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row d-block">
                            <label className="d-block modal-label">
                              Images
                            </label>
                            <div className="row gy-2">
                              {linkData.images.map((imageData, imageIndex) => (
                                <div
                                  className="col-auto d-flex justify-content-between position-relative"
                                  key={imageIndex}
                                >
                                  {imageData?.url ? (
                                    <img
                                      className="preview-image"
                                      src={imageData.url}
                                      onClick={() =>
                                        openImageViewer(
                                          images.indexOf(imageData.url)
                                        )
                                      }
                                      alt={imageData.name}
                                      width={70}
                                      height={70}
                                    />
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
