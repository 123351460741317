import * as Yup from "yup";

export const UpdateAiChatIntialValues = {
    message: ""
  
};

  export const UpdateAiChatValidationSchema = Yup.object()
  .shape({
    message: Yup.string()
    .test('{{age}}', 'Age is missing', (value) =>
      value && value.toLowerCase().includes("{{age}}".toLowerCase()))
      .test('{{message}}', 'User Message is missing', (value) =>
      value && value.toLowerCase().includes("{{message}}".toLowerCase()))
    .required("Please enter message"),
  })
  .defined();