import React from "react";
import Select, { components } from "react-select";

const CustomSelect = ({ options, isLoading, onChange, ...props }) => {
  const LoadingIndicator = () => (
    <div align="center" colSpan={8}>
      <div className="loader-wrapper">
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );

  const LoadingOption = (props) => (
    <components.Option {...props}>
      <LoadingIndicator />
    </components.Option>
  );

  return (
    <Select
      {...props}
      options={
        isLoading ? [{ label: "Loading...", value: "loading" }] : options
      }
      components={{ Option: isLoading ? LoadingOption : components.Option }}
      onChange={(option) => {
        onChange(option ? option : null);
      }}
      isClearable
      styles={{
        option: (styles, { isDisabled }) => ({
          ...styles,
          cursor: isDisabled ? "not-allowed" : "pointer",
          marginTop: "0px",
          marginBottom: "0px",
        }),
        control: (styles) => ({
          ...styles,
          backgroundColor: "#F7F5F5",
          borderRadius: "10px",
        }),
        menu: (styles) => ({
          ...styles,
          backgroundColor: "#F7F5F5",
          borderRadius: "10px",
          overflow: "hidden",
        }),
      }}
    />
  );
};

export default CustomSelect;
