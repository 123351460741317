/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { adminGetUserPermissionService } from '../../Api/user';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SearchIcon from '@mui/icons-material/Search';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
// import SyncIcon from '@mui/icons-material/Sync';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import './style.css';

const AdminSidebar = ({ handleOnClick }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [permission, setPermission] = useState([]);
  const [role, setRole] = useState();
  const location = useLocation()

  const getPermission = async () => {
    await adminGetUserPermissionService()
      .then((res) => {
        const permissionArr = res?.data.data.permissions.role_meta;
        const resourceArr = permissionArr.map((value) => {
          return value.role_resource.resource_key;
        });
        setPermission(resourceArr);
        localStorage.setItem('adminPermission', JSON.stringify(resourceArr));
      })
      .catch((err) => {
        {/**
             if (typeof err.response.data.message !== "undefined") {
             ErrorToast(err.response.data.message || "Server Error!!");
           } else {
             ErrorToast(err?.message || "Server Error!!");
           }
             */}
      });
  };
  useEffect(() => {
    const role = JSON.parse(localStorage.getItem('userData'));
    setRole(role?.role);
    if (role?.role === 'sub_admin') {
      getPermission();
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <>
      <div className="d-flex flex-column flex-shrink-0 p-1" style={{ maxwidth: "280px" }}>
        {/* <Link to="/" className="d-flex align-items-center justify-content-center mb-3 mb-md-0 mb-sm-0 me-md-auto link-light text-decoration-none">
          <div className="fs-4">ove</div>
        </Link> */}
        <div className='sidebar-inner slimscroll'>
          <div id='sidebar-menu' className='sidebar-menu'>
            <ul className="nav nav-pills flex-column mb-auto p-0">
              <li class="nav-item">
                <NavLink to='/admin/dashboard' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/dashboard')
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <DashboardIcon className='icon-width-height' />
                  <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/admin/admin-user-listing'
                  onClick={width < 770 && handleOnClick}
                  className={(location?.pathname === '/admin/admin-user-listing' || location?.pathname === "/admin/add-admin-user" || location?.pathname === "/admin/edit-admin-user")
                    ? "text-nowrap d-flex sidemenu-active" :
                    "text-nowrap d-flex "}
                >
                  <ManageAccountsIcon className='icon-width-height' />
                  <span>Admin User</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to='/admin/client-user-listing'
                  onClick={width < 770 && handleOnClick}
                  className={(location?.pathname === '/admin/client-user-listing')
                    ? "text-nowrap d-flex sidemenu-active" :
                    "text-nowrap d-flex "}
                >
                  <PeopleIcon className='icon-width-height' />
                  <span>Client User</span>
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to='/admin/guide-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/guide-listing' || location?.pathname === "/admin/add-guide" || location?.pathname === "/admin/edit-guide")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <HelpIcon className='icon-width-height' />
                  <span>Guide Product</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to='/admin/category-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/category-listing' || location?.pathname === "/admin/add-category" || location?.pathname === "/admin/edit-category")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <CategoryIcon className='icon-width-height' />
                  <span>Category</span>
                </NavLink>
              </li>

              {/* <li>
                <NavLink to='/admin/flow-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/flow-listing' || location?.pathname === "/admin/add-flow" || location?.pathname === "/admin/edit-flow")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <SyncIcon className='icon-width-height' />
                  <span>Flow</span>
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink to='/admin/welfare-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/welfare-listing' || location?.pathname === "/admin/add-welfare" || location?.pathname === "/admin/edit-welfare")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <VolunteerActivismIcon className='icon-width-height' />
                  <span>Welfare</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to='/admin/product-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/product-listing' || location?.pathname === "/admin/add-product" || location?.pathname === "/admin/edit-product")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <InventoryIcon className='icon-width-height' />
                  <span>Products</span>
                </NavLink>
              </li>

              {/*  <li>
                <NavLink to='/admin/product-variations' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/product-variations' || location?.pathname === "/admin/add-product-variations" || location?.pathname === "/admin/edit-product-variations")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <ViewCarouselIcon className='icon-width-height' />
                  <span>Product Variation</span>
                </NavLink>
              </li> */}

              {/*  <li>
                <NavLink to='/admin/discover-listing' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/discover-listing' || location?.pathname === "/admin/add-discover" || location?.pathname === "/admin/edit-discover")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <SearchIcon className='icon-width-height' />
                  <span>Discover</span>
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink to='/admin/emoji' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/emoji' || location?.pathname === "/admin/add-emoji")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <EmojiEmotionsIcon className='icon-width-height' />
                  <span>Emoji</span>
                </NavLink>
              </li> */}
              {/* 
              <li>
                <NavLink to='/admin/app-notifications' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/app-notifications' || location?.pathname === "/admin/add-notifications")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <NotificationsIcon className='icon-width-height' />
                  <span>App Notification</span>
                </NavLink>
              </li> */}

              {/*  <li>
                <NavLink to='/admin/symptom' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/symptom' || location?.pathname === "/admin/symptom")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <HealthAndSafetyIcon className='icon-width-height' />
                  <span>Symptom</span>
                </NavLink>
              </li> */}

              {/* <li>
                <NavLink to='/admin/firebase-notifications' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/firebase-notifications' || location?.pathname === "/admin/firebase-notifications")
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <CircleNotificationsIcon className='icon-width-height' />
                  <span>Firebase Notification</span>
                </NavLink>
              </li> */}

              <li>
                <NavLink to='/admin/setting' onClick={width < 770 && handleOnClick} className={(location?.pathname === '/admin/setting')
                  ? "text-nowrap d-flex sidemenu-active" :
                  "text-nowrap d-flex "}>
                  <SettingsIcon className='icon-width-height' />
                  <span>Setting</span>
                </NavLink>
              </li>

              <li className='mb-0'>
                <NavLink
                  to="/admin/logout"
                  onClick={width < 770 && handleOnClick}
                  className={(location?.pathname === '/admin/logout')
                    ? "text-nowrap d-flex sidemenu-active" :
                    "text-nowrap d-flex "}
                >
                  <LogoutIcon className='icon-width-height' />
                  <span className='ms-2'>Logout</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminSidebar;
