import * as Yup from "yup";

export const DiscoverInitialValue = {
  name: "",
  title: "",
  subTitle: "Click here to learn more",
  description: [
    {
      description: "",
    },
  ],
  itemDescription: "",
  icon: "",
  image: "",
  type: "",
};

export const DiscoverSearchBarInitialValues = {
  search: "",
};

export const AddDiscoverValidation = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  title: Yup.string().required("Please enter title"),
  subTitle: Yup.string().required("Please enter sub title"),
  description: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("Please enter description"),
    })
  ),
  itemDescription: Yup.string().required("Please enter item description"),
  icon: Yup.string().nullable().required("Please select icon"),
  image: Yup.string().nullable().required("Please select image"),
  type: Yup.string().nullable().required("Please select type"),
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required("Please enter question"),
      image: Yup.string().required("Please add image"),
      answers: Yup.array().of(
        Yup.object().shape({
          answer: Yup.string().required("Please enter answer"),
          correct: Yup.boolean().required(
            "Please select any one correct option"
          ),
        })
      ),
    })
  ),
});

export const EditDiscoverValidation = Yup.object()
  .shape({
    name: Yup.string().required("Please enter name"),
    title: Yup.string().required("Please enter title"),
    subTitle: Yup.string().required("Please enter sub title"),
    description: Yup.array()
      .of(
        Yup.object().shape({
          description: Yup.string()
            .ensure()
            .required("Please enter description"),
        })
      )
      .required("Please enter description"),
    itemDescription: Yup.string().required("Please enter item description"),
    icon: Yup.string().nullable().required("Please select icon"),
    image: Yup.string().nullable().required("Please select image"),
    type: Yup.string().nullable().required("Please select type"),
    questions: Yup.array()
      .of(
        Yup.object().shape({
          question: Yup.string().required("Please enter question"),
          image: Yup.string().required("Please add image"),
          answers: Yup.array().of(
            Yup.object().shape({
              answer: Yup.string().required("Please enter answer"),
              correct: Yup.boolean().required(
                "Please select any one correct option"
              ),
            })
          ),
        })
      )
      .nullable(),
  })
  .defined();
