import * as Yup from 'yup';

export const userSearchBarInitialValues = {
  is_paid: {
    label: 'Both',
    value: null,
  },
  search: '',
};

export const userInitialValues = {
  name: '',
  email: '',
  mobileNo: '',
  status: false
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const UserValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required('Please enter name'),
    email: Yup.string().email().required("Please enter email"),
    mobileNo: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Please enter mobile number"),
  })
  .defined();

  export const UserSearchBarInitialValues = {
    search: '',
  };
