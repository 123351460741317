import Api from '../Utils/Axios';

export const adminGetUserPermissionService = async () => {
  return new Promise((resolve, reject) => {
    try {
      resolve(Api.get('/admin/users/permissions/retrive'));
    } catch (error) {
      reject(error);
    }
  });
};
