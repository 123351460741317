import * as Yup from "yup";
import { string } from "yup";

export const ProductIntialValues = {
  name: "",
  description: "",
  category: null,
  // flow: null,
  links: [
    {
      link: '',
      // amazonAffiliateLink:'',
      images: [],
      items: [{ variationId: null, variationValueId: null }],
      availablity: {
        Message: "",
        Type: "",
      },
      price: {
        amount: "",
        currency: "",
        displayAmount: "",
      },
    },
  ],
};

// export const ProductValidationSchema = Yup.object()
//   .shape({
//     name: Yup.string().required("Please enter name"),
//     category: Yup.string().nullable().required("Please select category"),
//     description:Yup.string().required("Please enter description"),
//     links: Yup.array().of(
//       Yup.object().shape({
//         link: Yup.string()
//           .url("Please enter valid link")
//           .required("Please enter link"),
//         // amazonAffiliateLink:  Yup.string()
//         // .url('Please enter valid link'),
//         // // .required('Please enter link'),
//         images: Yup.array()
//           .min(1, "Please add images")
//           .required("Please add images"),
//         items: Yup.array().of(
//           Yup.object().shape({
//             variationId: Yup.string().nullable(),
//             variationValueId: Yup.string()
//               .nullable()
//               .when("variationId", {
//                 is: (val) => val && val.length > 0,
//                 then: (schema) =>
//                   schema.required("Please select product variation value"),
//                 otherwise: (schema) => schema,
//               }),
//           })
//         ),
//       })
//     ).min(1, 'At least one variation is required').required('At least one variation is required')
//   })
//   .defined();


export const ProductValidationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Please enter name"),
    category: Yup.string().nullable().required("Please select category"),
    description: Yup.string().required("Please enter description"),
    links: Yup.array()
      .of(
        Yup.object().shape({
          link: Yup.string()
            .url("Please enter valid link")
            .required("Please enter link"),
          images: Yup.array()
            .min(1, "Please add images")
            .required("Please add images"),
          items: Yup.array()
            .of(
              Yup.object().shape({
                variationId: Yup.string().nullable(),
                variationValueId: Yup.string()
                  .nullable()
                  .when("variationId", {
                    is: (val) => val && val.length > 0,
                    then: (schema) =>
                      schema.required("Please select product variation value"),
                    otherwise: (schema) => schema,
                  }),
              })
            )
            .test(
              "unique-variation",
              "Duplicate variant values detected",
              function (value) {
                value
                const result = value.reduce((acc, obj) => {
                  const key = `${obj.variationId}:${obj.variationValueId}`;
          
                  if (acc.seen[key]) {
                      acc.unique = false;
                  } else {
                      acc.seen[key] = true;
                  }
          
                  return acc;
              }, { seen: {}, unique: true });
          
              return result.unique;
              
              }
            ),
        })
      )
      .min(1, "At least one variation is required")
      .required("At least one variation is required"),
  })
  .defined();


export const InsightSearchBarInitialValues = {
  search: "",
  country_id: null,
  type_id: null,
  year: { label: 2023, value: 2023 },
};

export const ProductSearchBarInitialValues = {
  search: "",
  category:null,
};
